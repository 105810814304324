import PropTypes from "prop-types";
import DashboardMobileItem from "./DashboardMobileItem";

const DashboardMobile = ({ data }) => {
  return (
    <div id="order-accordion" className="order-accordion">
      {data.map((order) => <DashboardMobileItem order={order} key={order.id} />)}
    </div>
  );
};

DashboardMobile.propTypes = {
  data: PropTypes.array.isRequired,
};

export default DashboardMobile;
