import { Elements, useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
import PropTypes from "prop-types";
import { useState } from "react";

import { ReactComponent as PaypalIcon } from "../../assets/icons/payment-details-paypal.svg";
import { ReactComponent as MastercardIcon } from "../../assets/icons/payment-details-mastercard.svg";
import { ReactComponent as VisaIcon } from "../../assets/icons/payment-details-visa.svg";
import { ReactComponent as DiscoverIcon } from "../../assets/icons/payment-details-discover.svg";
import styles from "../Payment/Payment.module.scss";
import { useStores } from "../../hooks/use-stores";
import ReactGA from "react-ga4";
/* eslint-disable */

const fildStyle = {
  base: {
    fontSize: "16px",
    color: "#263238",
    fontFamily: "Montserrat, sans-serif",

    "::placeholder": { color: "#263238" },
  },
};

const fildFonts = [
  {
    family: "Montserrat",
    cssSrc: "https://fonts.googleapis.com/css2?family=Montserrat&display=swap",
  },
];

const Payment = ({ subscribeInfo, paymentHandler }) => (
  <Elements stripe={loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY)} options={{ fonts: fildFonts }}>
    <Stripe subscribeInfo={subscribeInfo} paymentHandler={paymentHandler} />
  </Elements>
);

Payment.propTypes = {
  subscribeInfo: PropTypes.object.isRequired,
  paymentHandler: PropTypes.func,
};

const Stripe = observer(({ subscribeInfo, paymentHandler }) => {
  const {
    plan: { clientSecret },
  } = useStores();
  const [wasSubmited, setSubmitedStatus] = useState(false);
  const [isProcessing, setProcessingTo] = useState(false);
  const [fullNameError, setFullNameError] = useState("");
  const [checkoutError, setCheckoutError] = useState();
  const [fullName, setFullName] = useState("");
  const elements = useElements();
  const history = useHistory();
  const stripe = useStripe();

  const paymentSubmit = (event) => {
    event.preventDefault();
    setSubmitedStatus(true);
    if (fullName.length < 2) {
      setFullNameError("Length should be 2 symbols at least");
      return;
    }
    processPayment();
  };

  const processPayment = async () => {
    setProcessingTo(true);
    try {
      const paymentMethodReq = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
        billing_details: fullName,
      });

      if (paymentMethodReq.error) {
        setCheckoutError(paymentMethodReq.error.message);
        setProcessingTo(false);
        return;
      }

      if (!clientSecret) {
        setCheckoutError("Client secret have not been provided");
        return;
      }

      const { error } = await stripe.confirmCardSetup(clientSecret, {
        payment_method: paymentMethodReq.paymentMethod.id,
      });

      if (error) {
        setCheckoutError(error.message);
        setProcessingTo(false);
        return;
      }

      setProcessingTo(false);

      paymentHandler();

      ReactGA.event({
        category: "Entering card data",
        action: "Entering card data",
      });

    } catch (err) {
      setCheckoutError(err.message);
    }
  };

  const changeFullname = (event) => {
    if (wasSubmited) {
      event.target.value.length > 1 && setFullNameError("");
      event.target.value.length < 2 && setFullNameError("Length should be 2 symbols at least");
    }
    setFullName(event.target.value);
  };

  return (
    <form className={styles.wrapper} onSubmit={paymentSubmit}>
      <div className={styles.fild}>
        <input className="global-input" placeholder="Full name" value={fullName} onChange={changeFullname} />
        <p className={`${styles.error} global-error`}>{fullNameError}</p>
      </div>
      <CardNumberElement className="global-input" options={{ placeholder: "Card number", style: fildStyle }} />
      <CardExpiryElement className="global-input" options={{ placeholder: "Expiry date", style: fildStyle }} />
      <CardCvcElement className="global-input" options={{ placeholder: "CVV", style: fildStyle }} />
      <div className={styles.container}>
        <PaypalIcon />
        <MastercardIcon />
        <VisaIcon />
        <DiscoverIcon />
      </div>
      <div className={styles.info}>
        <p className={styles.text}>Plan name:</p>
        <strong>{subscribeInfo.name}</strong>
        <p className={styles.text}>Plan details:</p>
        <strong>£{subscribeInfo.price.amount/100} per 1 month</strong>
      </div>
      <button className="global-button" type="submit" disabled={isProcessing || !stripe}>
        {isProcessing ? "Processing..." : "Confirm"}
      </button>
      {checkoutError && <p className={`${styles.mistake} global-error`}>{checkoutError}</p>}
    </form>
  );
});

Stripe.propTypes = {
  subscribeInfo: PropTypes.object.isRequired,
  paymentHandler: PropTypes.func,
};

export default Payment;
