import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import { ReactComponent as DashboardIcon } from "../../assets/icons/dashboard.svg";
import styles from "./AccessDenied.module.scss";

const AccessDenied = ({ error }) => (
  <div className={styles.wrapper}>
    <h3 className={styles.notification}>
      Access denied
      <br />
      {error}
    </h3>
    <Link className={`${styles.link} global-button white`} to="/dashboard">
      <DashboardIcon className={styles.icon} />
      Back to Dashboard
    </Link>
  </div>
);

AccessDenied.propTypes = { error: PropTypes.string };

export default AccessDenied;
