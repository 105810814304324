export default {
  fileUpload: {
    path: "/file/upload",
    method: "post",
  },
  delete: {
    path: "/file/:id",
    method: "delete",
  },
  attach: {
    path: "/order/:id/attach-file",
    method: "patch",
  },
};
