import PropTypes from "prop-types";
import styles from "./MainPageWrapper.module.scss";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
/* eslint-disable */

const MainPageWrapper = ({ children }) => {
  const [currentPath, setCurrentPath] = useState("");
  const loc = useLocation();

  useEffect(() => {
    setCurrentPath(loc.pathname);
  }, [loc]);

  const isExcludedPath = (path) => {
    const defaultPath = {
      "/about-us": true,
      "/pricing": true,
      "/contact": true,
    };
    return defaultPath[path];
  };

  return (
      <section className={currentPath !== "/contact" && styles.wrapper}>
        <div className={styles.receptacle}>
          {!isExcludedPath(currentPath) ? <h1 className={styles.title}>SaaS Logistics Platform</h1> : null}
          {children}
        </div>
        {!isExcludedPath(currentPath) ? (
          <div className={styles.heroImage__wrapper}>
            <div className={styles.heroImage__inner}>
              <iframe
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                src="https://www.youtube.com/embed/duESQD3hj3Y"
                title="YouTube video player"
                className={styles.frame}
                frameBorder="0"
                allowFullScreen></iframe>
            </div>
          </div>
        ) : null}
      </section>
  );
};

MainPageWrapper.propTypes = {
  children: PropTypes.element.isRequired,
};

export default MainPageWrapper;
