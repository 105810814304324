import { useEffect, useState, useRef } from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import moment from "moment";

import CompanyDetailsForm from "../../components/CompanyDetailsForm/CompanyDetailsForm";
import { ReactComponent as DashboardIcon } from "../../assets/icons/dashboard.svg";
import UserDetailsForm from "../../components/UserDetailsForm/UserDetailsForm";
import CancelMySubscription from "./CancelMySubscription/CancelMySubscription";
import { ReactComponent as TickIcon } from "../../assets/icons/tick.svg";
import { useStores } from "../../hooks/use-stores";
import styles from "./UserProfile.module.scss";
import { toJS } from "mobx";
import Modal from "@mui/material/Modal";
import ReactGA from "react-ga4";

const UserProfile = () => {
  const {
    userDetails: {
      getCustomerPortalLink,
      updateCompanyDetails,
      updateIdentityError,
      removeUserProfile,
      updateUserDetails,
      isSubscribeActive,
      isPlanSelected,
      getUserProfile,
      isLoading,
      identity,
      KYCError,
      KYC,
    },
  } = useStores();
  const canMakePayment = identity.subscription?.status !== "trialing" && identity.subscription?.status !== "new" && identity.subscription?.status !== "active" && identity.subscription?.status !== "incomplete_expired" && identity.subscription !== null;
  const [cancelModalActive, setCancelModalActive] = useState(false);
  const [modalActive, setModalActive] = useState(false);
  const defaulCompanyDetails = useRef({});
  const defaultUserDetails = useRef({});
  const isTrialingActive = identity.subscription?.status === "trialing";

  useEffect(() => {
    if (identity) {
      defaultUserDetails.current = {
        fullName: identity.fullName,
        email: identity.email,
        confirmPassword: "",
        oldPassword: "",
        password: "",
      };
    }
  }, [identity]);

  useEffect(() => {
    if (KYC) {
      defaulCompanyDetails.current = {
        ...(KYC.branchOffices && { branchOffices: KYC.branchOffices }),
        ...(KYC.tax && { tax: KYC.tax }),
        customerAddress: KYC.customerAddress,
        natureOfBusiness: KYC.natureOfBusiness,
        establishedDate: KYC.establishedDate,
        customerName: KYC.customerName,
        directorName: KYC.directorName,
        constitution: KYC.constitution,
        eori: KYC.eori,
        vat: KYC.vat,
        pan: KYC.pan,
      };
      if (KYC.status === "confirmed") {
        ReactGA.event({
          category: "Company verification",
          action: "Company verification",
        });
      };
    }
  }, [KYC]);

  useEffect(() => getUserProfile(), []);

  const userDetailsFormHandler = async (formData, onSuccess) => {
    if (formData.email === identity.email) {
      delete formData.email;
    }
    const payload = sanitizePayload(formData);
    const isSuccess = await updateUserDetails(payload);
    isSuccess && onSuccess();
  };

  const sanitizePayload = (data) => {
    const dataCopy = { ...data };

    for (const y in dataCopy) {
      if (!dataCopy[y]) {
        delete dataCopy[y];
      }
    }

    return dataCopy;
  };

  const companyDetailsFormHandler = async (formData, onSuccess) => {
    const isSuccess = await updateCompanyDetails(formData);
    isSuccess && onSuccess();
  };

  const handleClose = () => {
    setModalActive(false);
    setCancelModalActive(false);
  };

  const onModalOpenAccount = () => setModalActive(true);

  const onRemoveCancelSubscr = () => alert("Cancel");

  const onRemoveAccount = async () => {
    await removeUserProfile();
    setModalActive(false);
  };

  const onRedirect = async () => {
    const link = await getCustomerPortalLink();
    link?.url && window.open(link.url).focus();
  };

  let subscriptionData;
  if (isSubscribeActive) {
    subscriptionData = <span className={styles.date}>{moment(identity.subscription?.periodEnd).format("DD, MMMM, YYYY").replaceAll(",", "")}</span>;
  } else {
    subscriptionData = <></>;
  }

  return (
    <section className={styles.wrapper}>
      <h2 className={styles.title}>User profile</h2>
      <div className={styles.receptacle}>
        <div className={styles.headline}>
          <h3 className={styles.heading}>User details</h3>
          <Link to="/dashboard" className={`global-button white ${styles.link}`}>
            <DashboardIcon className={styles.icon} /> my dashboard
          </Link>
        </div>
        <div className={styles.section}>
          <UserDetailsForm
            defaultFormData={defaultUserDetails.current}
            formHandler={userDetailsFormHandler}
            isLoading={isLoading}
            error={updateIdentityError}
            className={styles.form}
          />
          <div className={styles.info}>
            {identity.subscription?.status === "trialing" && identity.subscription !== null && (
              <p className={styles.expiration}>
                {isSubscribeActive ? "Trialing until:" : "Subscription expired"}{" "}
                {subscriptionData}
              </p>
            )}
            {identity.subscription?.status !== "new" && identity.subscription !== null && !isTrialingActive && (
              <p className={styles.expiration}>
                {isSubscribeActive ? "Next payment date:" : "Subscription expired"}{" "}
                {subscriptionData}
              </p>
            )}
            {isPlanSelected && (
              <button onClick={onRedirect} className={`${styles.button} global-button white`}>
                Change credit card
              </button>
            )}
            {canMakePayment && (
              <button onClick={onRedirect} className={`${styles.button} global-button light-green`}>
                Make payment
              </button>
            )}
            {!isPlanSelected && (
              <Link to="/first-login" className={`${styles.button} global-button light-green`}>
                Сhoose subscription
              </Link>
            )}
            {isPlanSelected && (
              <button onClick={onRedirect} className={`${styles.cancelation}`}>
                <span className={styles.cross}></span>Cancel my subscription
              </button>
            )}
          </div>
        </div>
      </div>
      <div className={styles.receptacle}>
        <div className={styles.headline}>
          <h3 className={styles.heading}>Company details</h3>
          <p className={`${styles.heading} ${styles.notification}`}>
            {KYC?.status === "inreview" && "Your details are not verified by staff yet"}
            {KYC?.status === "confirmed" && (
              <span className={styles.tick}>
                <TickIcon />
              </span>
            )}
          </p>
        </div>
        <CompanyDetailsForm
          defaultFormData={defaulCompanyDetails.current}
          formHandler={companyDetailsFormHandler}
          isLoading={isLoading}
          error={KYCError}
          onAccountDelete={onModalOpenAccount}
          isInfoApproved={KYC?.status === "confirmed"}
        />
        <Modal open={modalActive} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <CancelMySubscription
            head={<span>Warning</span>}
            content={<span>You will lose the ability to access the details about operetions. Are you sure you want to delete account?</span>}
            onCancel={handleClose}
            onAccept={onRemoveAccount}
          />
        </Modal>
        <Modal open={cancelModalActive} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <CancelMySubscription
            head={<span>Alert</span>}
            content={<span>You are about to cancel your subscription. This action will not let you create new orders. Are you sure you want to proceed?</span>}
            onCancel={handleClose}
            onAccept={onRemoveCancelSubscr}
          />
        </Modal>
      </div>
    </section>
  );
};

export default observer(UserProfile);
