import * as React from "react";
import styles from "./NavBlock.module.scss";
import CallButtons from "../CallButtons/CallButtons";
import Pages from "../Pages/Pages";

const NavBlock = () => {
  const [isMenuOpen, setMenuOpen] = React.useState(false);
  const toggleBurgerMenu = () => {
    const open = !isMenuOpen;
    setMenuOpen(open);
  };

  const closeMobileNavigation = () => setMenuOpen(false);

  return (
    <div id="nav-block" className={styles.navBlock}>
      <div className={styles.overlay}></div>
      <Pages isOpen={isMenuOpen} />
      <CallButtons closeMobileNavigation={closeMobileNavigation} onToggle={toggleBurgerMenu} isMenuOpen={isMenuOpen}/>
    </div>
  );
};

export default NavBlock;
