import { useState } from "react";

import Form from "../../components/Form/Form";
import styles from "./ForgotPassword.module.scss";
import { useStores } from "../../hooks/use-stores";

const ForgotPassword = () => {
  const [isSuccess, setSuccessStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const { authentication: { resetPasswordWithEmail } } = useStores();

  const sendUserDataToApi = async (data) => {
    setLoading(true);
    try {
      await resetPasswordWithEmail(data.email);
      setSuccessStatus(true);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  return (
    <>
      <p className={styles.advice}>
        {isSuccess
          ? "We have sent an email to your address. Please check it and follow the instruction"
          : "Type your email address and we will send you instructions to recover your password"}
      </p>
      {!isSuccess && <Form type="recover" loading={loading} dataHandler={sendUserDataToApi} />}
    </>
  );
};

export default ForgotPassword;
