import { ReactComponent as FacebookIcon } from "../../../../assets/icons/facebook.svg";
import { ReactComponent as InstagramIcon } from "../../../../assets/icons/instagram.svg";

import styles from "./SocialLinks.module.scss";

const SocialLinks = () => {
  return (
    <ul className={styles.wrapper}>
      <a className={styles.link} href="https://www.facebook.com/" target="_blank" rel="noreferrer">
        <FacebookIcon className={styles.icon}/>
      </a>
      <a className={styles.link} href="https://www.instagram.com/" target="_blank" rel="noreferrer">
        <InstagramIcon className={styles.icon}/>
      </a>
    </ul>
  );
};

export default SocialLinks;
