import { Link, useHistory, useParams } from "react-router-dom";
import { Backdrop, Box, Fade, Modal } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useStores } from "../../hooks/use-stores";
import { observer } from "mobx-react-lite";
import PropTypes from "prop-types";
import { toJS } from "mobx";

import { ReactComponent as DashboardIcon } from "../../assets/icons/dashboard.svg";
import OrderDetails from "../../components/OrderDetails/OrderDetails";
import AccessDenied from "../../components/AccessDenied/AccessDenied";
import ScrolledStatus from "../ScrolledStatus/ScrolledStatus";
import { orderStatuses } from "../../helpers/contstants";
import Prompt from "../../components/Prompt/Prompt";
import Alert from "../../components/Alert/Alert";
import styles from "./Order.module.scss";

const Order = ({ location: { state } }) => {
  const [isCencelationVisible, setCencelationVisibility] = useState(false);
  const {
    order: { getCurrentOrder, currentOrder, cancelOrder, error, setCurrentOrder },
    messages: { setMessages },
  } = useStores();
  const successMessage = useRef("");
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    if (state?.successMessage) successMessage.current = state.successMessage;
    history.replace();
    getCurrentOrder(id);
    return () => {
      setCurrentOrder(null);
      setMessages([]);
    };
  }, []);

  const onRedirect = () => {
    history.push({
      pathname: `/shipping-details-update/${currentOrder.id}`,
      state: { currentOrder: toJS(currentOrder) },
    });
  };

  const closeCencelation = () => setCencelationVisibility(false);
  const openCencelation = () => setCencelationVisibility(true);

  const removeOrder = (id) => async () => {
    await cancelOrder(id);
    history.push("/dashborad");
  };

  return (
    <>
      <section className={styles.wrapper}>
        {currentOrder ? (
          <>
            <div className={styles.headline}>
              <div className={styles.info}>
                <p className={styles.text}>
                  Order name: <strong>{currentOrder.name}</strong>
                </p>
                <p className={styles.text}>
                  Status: <strong>{orderStatuses[currentOrder.status]}</strong>
                </p>
              </div>
              <div className={styles.control}>
                <Link className={`${styles.link} ${styles.reference} global-button white`} to="/dashboard">
                  <DashboardIcon className={styles.icon} />
                  My Dashboard
                </Link>
                {currentOrder.status !== "canceled" && <button className={styles.cancelation} onClick={openCencelation}>
                  <span className={styles.cross}></span>Cancel order
                </button>}
              </div>
              {successMessage.current && <h3 className={styles.notification}>{successMessage.current}</h3>}
            </div>
            <ScrolledStatus currentStatus={currentOrder.status} />
            <OrderDetails onRedirect={onRedirect} order={currentOrder} orderCreateAt={currentOrder.createdAt}/>
          </>
        ) : error ? (
          <AccessDenied error={error} />
        ) : (
          <p>Loading...</p>
        )}
      </section>
      <Modal
        className={styles.modal}
        open={isCencelationVisible}
        closeAfterTransition
        BackdropComponent={Backdrop}
        sx={{
          outline: "none",
        }}
        BackdropProps={{
          timeout: 500,
        }}
        onClose={closeCencelation}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Fade in={isCencelationVisible}>
          <Box
            sx={{
              outline: "none",
            }}
          >
            <Alert close={closeCencelation}>
              <Prompt onConfirm={removeOrder(id)} onUnconfirm={closeCencelation} title="Alert" notification="Are you sure you want to cancel your order?" />
            </Alert>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

Order.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      successMessage: PropTypes.string,
    }),
  }).isRequired,
};

export default observer(Order);
