import PropTypes from "prop-types";

import styles from "./Prompt.module.scss";

const Prompt = ({ title, notification, onConfirm, onUnconfirm }) => {
  return (
    <div className={styles.wrapper}>
      <h5 className={styles.title}>{title}</h5>
      <p className={styles.notification}>{notification}</p>
      <button className={`${styles.button} global-button`} onClick={onUnconfirm} autoFocus>
        No
      </button>
      <button className={`${styles.button} global-button white`} onClick={onConfirm}>
        Yes
      </button>
    </div>
  );
};

Prompt.propTypes = {
  title: PropTypes.string,
  notification: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onUnconfirm: PropTypes.func.isRequired,
};

export default Prompt;
