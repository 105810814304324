import userDetailsEndpoints from "../api/endpoints/user-details";
import { observable, action, makeObservable } from "mobx";
import { axios } from "../utils/axios";

class UserDetails {
  updateIdentityError = null;
  isSubscribeActive = null;
  isPlanSelected = null;
  identityError = null;
  isFirstLogin = null;
  isLoading = false;
  identity = null;
  KYCError = null;
  KYC = null;
  PANCompanies = [];

  async getCustomerPortalLink() {
    const method = userDetailsEndpoints.customerPortal.method;
    const path = userDetailsEndpoints.customerPortal.path;
    try {
      return await axios[method](path);
    } catch (e) {
      console.error(e);
    }
  }

  async removeUserProfile() {
    const method = userDetailsEndpoints.deleteAccount.method;
    const path = userDetailsEndpoints.deleteAccount.path.replace(":user-id", this.identity.id);
    try {
      await axios[method](path);
    } catch (e) {
      console.error(e);
    }
  }

  async getUserProfile() {
    this.setLoading(true);
    this.setDefaultError();
    await this.getUserDetails();
    await this.getKYC();
    this.setLoading(false);
  }

  async getUserDetails() {
    const method = userDetailsEndpoints.identity.method;
    const path = userDetailsEndpoints.identity.path;
    try {
      const result = await axios[method](path);
      this.setIdentity(result);
      result.subscription?.plan && result.subscription?.status !== "incomplete_expired" && this.setPlanSelectedStatus(true);
      this.setSubscribeStatus(["active", "trialing"].includes(result.subscription?.status));
    } catch (error) {
      this.setIdentityError("Failed to upload your credentials! Please contact support");
      console.error(error);
    }
  }

  async getKYC() {
    try {
      const method = userDetailsEndpoints.KYC.method;
      const path = userDetailsEndpoints.KYC.path.replace(":id", this.identity.id);
      const result = await axios[method](path);
      this.setKYC(result);
      this.setFirstLoginStatus(false);
    } catch (error) {
      error.response?.data.error_description === "resource_not_found_exception_message" && this.setFirstLoginStatus(true);
      console.error(error);
    }
  }

  async getPANCompanies(data) {
    const method = userDetailsEndpoints.PAN.method;
    const path = userDetailsEndpoints.PAN.path;

    try {
      const resp = await axios[method](path, {
        params: {
          "filter[search]": data,
          limit: 10,
        },
      });
      this.setPANCompanies(resp.results);
    } catch (e) {
      console.error(e);
    }
  }

  async updateUserDetails(payload) {
    this.setLoading(true);
    this.setIdentityError(null);
    const method = userDetailsEndpoints.updateUserDetails.method;
    const path = userDetailsEndpoints.updateUserDetails.path.replace(":id", this.identity.id);
    try {
      await axios[method](path, { ...payload });
      return "success";
    } catch (error) {
      console.error(error);
      this.setUpdateIdentityError("Something went wrong! Please contact support or try again later.");
    } finally {
      this.setLoading(false);
    }
  }

  async updateCompanyDetails(payload) {
    this.setLoading(true);
    this.setKYCError(null);
    const method = userDetailsEndpoints.updateCompanyDetails.method;
    const path = userDetailsEndpoints.updateCompanyDetails.path.replace(":kyc-id", this.KYC.id);
    try {
      const response = await axios[method](path, payload);
      this.setKYC(response);
      return response;
    } catch (error) {
      console.error(error);
      this.setKYCError("Something went wrong! Please contact support or try again later.");
    } finally {
      this.setLoading(false);
    }
  }

  async setCompanyDetails(payload) {
    this.setLoading(true);
    this.setKYCError(null);
    const method = userDetailsEndpoints.setCompanyDetails.method;
    const path = userDetailsEndpoints.setCompanyDetails.path;
    try {
      const response = await axios[method](path, payload);
      response && (await this.getKYC());
      return response;
    } catch (error) {
      console.error(error);
    }
    this.setLoading(false);
  }

  clearUserDetails() {
    this.setKYC(null);
    this.setIdentity(null);
    this.setSubscribeStatus(null);
    this.setFirstLoginStatus(null);
    this.setPlanSelectedStatus(null);
  }

  setUpdateIdentityError(error) {
    this.updateIdentityError = error;
  }

  setPANCompanies(data) {
    this.PANCompanies = data;
  }

  setKYC(payload) {
    this.KYC = payload;
  }

  setIdentity(payload) {
    this.identity = payload;
  }

  setSubscribeStatus(status) {
    this.isSubscribeActive = status;
  }

  setIdentityError(error) {
    this.identityError = error;
  }

  setPlanSelectedStatus(status) {
    this.isPlanSelected = status;
  }

  setLoading(status) {
    this.isLoading = status;
  }

  setFirstLoginStatus(status) {
    this.isFirstLogin = status;
  }

  setDefaultError() {
    this.KYCError = null;
    this.identityError = null;
  }

  setKYCError(error) {
    this.KYCError = error;
  }
}

const userDetails = new UserDetails();

makeObservable(userDetails, {
  setUpdateIdentityError: action.bound,
  setPlanSelectedStatus: action.bound,
  updateCompanyDetails: action.bound,
  setFirstLoginStatus: action.bound,
  removeUserProfile: action.bound,
  updateUserDetails: action.bound,
  setCompanyDetails: action.bound,
  updateIdentityError: observable,
  clearUserDetails: action.bound,
  setIdentityError: action.bound,
  setDefaultError: action.bound,
  isSubscribeActive: observable,
  getUserDetails: action.bound,
  getUserProfile: action.bound,
  isPlanSelected: observable,
  identityError: observable,
  setKYCError: action.bound,
  getPANCompanies: action.bound,
  setPANCompanies: action.bound,
  setIdentity: action.bound,
  setLoading: action.bound,
  isFirstLogin: observable,
  isLoading: observable,
  KYCError: observable,
  PANCompanies: observable,
  getKYC: action.bound,
  setKYC: action.bound,
  identity: observable,
  KYC: observable,
});

export default userDetails;
