import styles from "./NavLinks.module.scss";
import { NavLink } from "react-router-dom";

const NavLinks = () => {
  return (
    <ul className={styles.navLinks}>
      <li>
        <NavLink className={styles.link} activeClassName={styles.selected} to="/about-us">
          About us
        </NavLink>
      </li>
      <li>
        <NavLink className={styles.link} activeClassName={styles.selected} to="/pricing">
          Pricing
        </NavLink>
      </li>
      <li>
        <NavLink className={styles.link} activeClassName={styles.selected} to="/contact">
          Contact
        </NavLink>
      </li>
    </ul>
  );
};

export default NavLinks;
