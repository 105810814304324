import styles from "./Pages.module.scss";
import NavLinks from "../NavBlock/NavLinks/NavLinks";
import SocialLinks from "../NavBlock/SocialLinks/SocialLinks";
import PropTypes from "prop-types";

const Pages = ({ isOpen }) => {
  const isActive = isOpen ? styles.active : "";
  const classNames = `${styles.pages} ${isActive}`;
  return (
    <div className={classNames}>
      <nav>
        <NavLinks />
        <SocialLinks />
      </nav>
    </div>
  );
};

export default Pages;

Pages.propTypes = {
  isOpen: PropTypes.bool,
};
