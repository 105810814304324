import PropTypes from "prop-types";
import { observer } from "mobx-react-lite";

import { ReactComponent as CrossIcon } from "../../../assets/icons/cross-icon.svg";

import styles from "./CancelMySubscription.module.scss";
/* eslint-disable */

const CancelMySubscription = ( { head, content, onCancel, onAccept, action } ) => {
  return (
    <div className={styles.root}>
      <div className={styles.popupContent}>
        <h3 className={styles.popupContent__h3}>{head}</h3>
        <p className={styles.popupContent__p}>{content}</p>
        {!action
          ? <div className={styles.popupContent__row}>
              <div className={styles.popupContent__col}>
                <button className={styles.buttonNo} onClick={onCancel}>NO</button>
              </div>
              <div className={styles.popupContent__col}>
                <button className={styles.buttonYes} onClick={onAccept}>YES</button>
              </div>
            </div>
          : <div className={styles.popupContent__registerAction}>
              <div className={styles.popupContent__col}>
                <button className={styles.buttonNo} onClick={onAccept}>{action}</button>
              </div>
            </div> 
        }
        <button id="popupClose" className={styles.popupClose} onClick={onCancel}>
          <CrossIcon />
        </button>
      </div>
    </div>
  );
};

export default observer(CancelMySubscription, {forwardRef: true});

CancelMySubscription.propTypes = {
  active: PropTypes.bool,
  head: PropTypes.element,
  content: PropTypes.element,
  onCancel: PropTypes.func,
  onAccept: PropTypes.func,
};
