import { useState, useEffect, useRef } from "react";
import { useStores } from "../../hooks/use-stores";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
import PropTypes from "prop-types";

import ProductShipment from "../../components/CreateOrder/ProductShipment";
import FindProduct from "../../components/CreateOrder/FindProduct";
import styles from "./CreateOrder.module.scss";

const CreateOrder = ({ location: { state } }) => {
  const [type, setType] = useState("find");
  const {
    order: { setOrder, error, setError, isLoading },
  } = useStores();
  const defaultFormData = useRef({});
  const catalogFile = useRef();
  const history = useHistory();

  const onChange = (e) => {
    const { value } = e.target;
    setType(value);
    setError(null);
  };

  console.log(state);
  useEffect(() => {
    if (state?.file) {
      catalogFile.current = state.file;
      defaultFormData.current = { name: state.file.goodsName };
      setType("shipment");
    }
    return () => history.replace();
  }, []);

  console.log(catalogFile);

  const findProductFormHandler = async (formData, onSuccess, files) => {
    const data = { stage: "find_product", ...(files?.length && { productDetailsFileId: files[0].id }), ...formData };
    const response = await setOrder(data);
    if (response) {
      onSuccess();
      history.push(`/order/${response.id}`);
    }
  };

  const productShipmentFormHandler = async (formData, onSuccess, files) => {
    console.log(files);
    const data = { stage: "product_shipment", ...(files?.length && { shipmentDetailsFileId: files[0].id }), ...formData };
    const response = await setOrder(data);
    response && onSuccess();
    response && history.push(`/order/${response.id}`);
  };

  return (
    <section className={styles.wrapper}>
      <h4 className={styles.title}>Create your order and one of our best operators will contact you as soon as possible</h4>
      <div className={styles.receptacle}>
        <input className={styles.input} onChange={onChange} type="radio" id="find" name="drone" value="find" checked={type === "find"} />
        <label className={styles.label} htmlFor="find">
          <span>Find product</span>
        </label>
        <input className={styles.input} onChange={onChange} type="radio" id="shipment" name="drone" value="shipment" checked={type === "shipment"} />
        <label className={styles.label} htmlFor="shipment">
          <span>Product shipment</span>
        </label>
      </div>
      {type === "find" && (
        <div className={styles.form}>
          <FindProduct formDataHandler={findProductFormHandler} isLoading={isLoading} error={error} />
        </div>
      )}
      {type === "shipment" && (
        <ProductShipment
          formDataHandler={productShipmentFormHandler}
          defaultFormData={defaultFormData.current}
          catalogFile={catalogFile.current}
          isLoading={isLoading}
          error={error}
        />
      )}
    </section>
  );
};

CreateOrder.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      file: PropTypes.shape({
        goodsName: PropTypes.string,
        name: PropTypes.string,
        url: PropTypes.string,
        id: PropTypes.string,
      }),
    }),
  }).isRequired,
};

export default observer(CreateOrder);
