import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import PropTypes from "prop-types";

import styles from "./Message.module.scss";

const Message = ({ message, order, timeFromNow, createdTime, className }) => (
  <>
    <li className={`${styles.wrapper} ${message.user ? styles.right : styles.left} ${className ?? ""}`} style={{ order: -order }}>
      <p className={styles.text}>
        {message.file ? (
          <a className={styles.link} href={message.file.url} target="_blank" rel="noreferrer">
            <InsertDriveFileOutlinedIcon className={styles.icon} fontSize="string" />
            {message.message}
          </a>
        ) : (
          message.message
        )}
      </p>
      <time className={styles.time}>
        <span>{message.admin && "SmartDrop"} </span>
        {createdTime.format("HH:mm")}
      </time>
    </li>
    {timeFromNow && (
      <p className={styles.headline} style={{ order: -order }}>
        {timeFromNow}
      </p>
    )}
  </>
);

export default Message;

Message.propTypes = {
  order: PropTypes.number.isRequired,
  timeFromNow: PropTypes.string,
  createdTime: PropTypes.object,
  className: PropTypes.string,
  message: PropTypes.object,
};
