import { useEffect } from "react";
import { observer } from "mobx-react-lite";

import SubscribePlan from "../../components/SubscribePlan/SubscribePlan";
import { useStores } from "../../hooks/use-stores";

const Pricing = () => {
  const {
    plan: { loading, planList, getPlanList, error: planError },
    authentication: { isAuthenticated },
  } = useStores();

  useEffect(() => getPlanList(), []);

  return (
    <main className="main">
      <div className="section-plan">
        <h1 className="h1">Subscription plan</h1>
        <SubscribePlan loading={loading} data={planList} type="pricing" error={planError} isAuthenticated={isAuthenticated} />
      </div>
    </main>
  );
};

export default observer(Pricing);
