import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";
import PropTypes from "prop-types";

import MessageSender from "../MessageSender/MessageSender";
import MessageList from "../MessageList/MessageList";
import { useStores } from "../../hooks/use-stores";
import styles from "./Messenger.module.scss";

const Messenger = ({ orderID, successMessageCreateAt, onRedirect }) => {
  const messageListNode = useRef();
  const {
    messages: { getMessages, messagesList },
    order: { orderStatus, currentOrder },
  } = useStores();

  useEffect(() => {
    const interval = setInterval(() => getMessages(orderID), 3000);
    getMessages(orderID);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={styles.wrapper}>
      <MessageList ref={messageListNode} orderStatus={orderStatus} messagesList={messagesList} successMessageCreateAt={successMessageCreateAt}/>
      <MessageSender orderID={orderID} messageListNode={messageListNode}/>
      {orderStatus === "shipping_details_negotiation" && (
        <button onClick={onRedirect} className={`${styles.button} global-button`}>{currentOrder.shipperCity ? "Edit order" : "Add product shipping details"}</button>
      )}
    </div>
  );
};

Messenger.propTypes = {
  orderID: PropTypes.number.isRequired,
  onRedirect: PropTypes.func.isRequired,
  successMessageCreateAt: PropTypes.number.isRequired,
};

export default observer(Messenger);
