import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import * as Yup from "yup";

import SuccessMessage from "../SuccessMessage/SuccessMessage";
import { emailRegExp } from "../../helpers/contstants";
import styles from "./UserDetailsForm.module.scss";

const validationSchema = Yup.object().shape({
  fullName: Yup.string().min(2, "Full name must be at least 2 characters").max(120, "Full name must not exceed 120 characters"),
  email: Yup.string().matches(emailRegExp, { message: "enter a valid email before and after '@'", excludeEmptyString: true }),
  oldPassword: Yup.string(),
  password: Yup.string()
    .test("empty-check", "Password must be at least 8 characters", (password) => !password || password.length >= 8),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords should match"),
});

const UserDetailsForm = ({ formHandler, defaultFormData = {}, isLoading, error, className }) => {
  const [isSuccess, setSuccessStatus] = useState(false);
  const {
    formState: { errors, isSubmitted },
    handleSubmit,
    resetField,
    getValues,
    register,
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: { ...defaultFormData },
    resolver: yupResolver(validationSchema),
  });

  const onSuccess = () => {
    setSuccessStatus(true);
    resetField("confirmPassword");
    resetField("oldPassword");
    resetField("password");
    setTimeout(() => setSuccessStatus(false), 4000);
  };

  useEffect(() => defaultFormData[Object.keys(defaultFormData)[0]] && reset(defaultFormData), [defaultFormData]);
  return (
    <form className={`${styles.wrapper} ${className}`} onSubmit={handleSubmit((data) => formHandler(data, onSuccess))}>
      <TextField
        helperText={isSubmitted && errors.fullName ? errors.fullName.message : " "}
        InputLabelProps={{ shrink: getValues().fullName }}
        color={`${isSubmitted ? "success" : "grey"}`}
        error={isSubmitted && errors.fullName}
        {...register("fullName")}
        label="Full name"
        variant="filled"
        name="fullName"
      />
      <TextField
        helperText={isSubmitted && errors.email ? errors.email.message : " "}
        InputLabelProps={{ shrink: getValues().fullName }}
        color={`${isSubmitted ? "success" : "grey"}`}
        error={isSubmitted && errors.email}
        {...register("email")}
        label="Email address"
        variant="filled"
        name="email"
      />
      <TextField
        helperText={isSubmitted && errors.oldPassword ? errors.oldPassword.message : " "}
        color={`${isSubmitted ? "success" : "grey"}`}
        error={isSubmitted && errors.oldPassword}
        {...register("oldPassword")}
        label="Current password"
        name="oldPassword"
        variant="filled"
        type="password"
      />
      <TextField
        helperText={isSubmitted && errors.password ? errors.password.message : " "}
        color={`${isSubmitted ? "success" : "grey"}`}
        error={isSubmitted && errors.password}
        {...register("password")}
        label="New password"
        variant="filled"
        name="password"
        type="password"
      />
      <TextField
        helperText={isSubmitted && errors.confirmPassword ? errors.confirmPassword.message : " "}
        color={`${isSubmitted ? "success" : "grey"}`}
        error={isSubmitted && errors.confirmPassword}
        {...register("confirmPassword")}
        label="Confirm new password"
        name="confirmPassword"
        variant="filled"
        type="password"
      />
      <button className={`${styles.button} global-button`} disabled={isLoading}>
        Save
      </button>
      {isSuccess && <SuccessMessage message={"Your details was successfully changed"} className={styles.notification} />}
      {error && <p className={styles.error}>{error}</p>}
    </form>
  );
};

UserDetailsForm.propTypes = {
  formHandler: PropTypes.func.isRequired,
  defaultFormData: PropTypes.object,
  isLoading: PropTypes.bool,
  error: PropTypes.string,
  className: PropTypes.string,
};

export default UserDetailsForm;
