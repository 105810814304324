export default {
  planList: {
    path: "/plan",
    method: "get",
  },
  subscribe: {
    path: "/subscription",
    method: "post",
  },
};
