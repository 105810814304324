import { observable, action, makeObservable } from "mobx";
import { axios } from "../utils/axios";
import planEndpoints from "../api/endpoints/plan";

class Plan {
  clientSecret = null;
  planList = [];
  loading = false;
  error = null;

  async getPlanList() {
    this.setLoading(true);
    this.setError(null);
    const path = `${planEndpoints.planList.path}?sortField=amount&sortDirection=ASC`;
    const method = planEndpoints.planList.method;
    try {
      const { results } = await axios[method](path);
      this.setResults(results);
    } catch (e) {
      console.error(e);
      this.setError("Something went wrong! Please contact support or try again later.");
    } finally {
      this.setLoading(false);
    }
  }

  async getClientSecret(id) {
    this.setLoading(true);
    this.setError(null);
    const path = planEndpoints.subscribe.path;
    const method = planEndpoints.subscribe.method;
    try {
      const response = await axios[method](path, {
        plan: id,
      });
      this.setClientSecret(response.stripeClientSecret);
      return response;
    } catch (e) {
      this.setError("Something went wrong! Please contact support or try again later.");
      console.error(e);
    } finally {
      this.setLoading(false);
    }
  }

  setClientSecret(secret) {
    this.clientSecret = secret;
  }

  setError(error) {
    this.error = error;
  }

  setLoading(loading) {
    this.loading = loading;
  }

  setResults(results) {
    this.planList = results;
  }
}

const plan = new Plan();

makeObservable(plan, {
  getPlanList: action.bound,
  setClientSecret: action.bound,
  getClientSecret: action.bound,
  setError: action.bound,
  setLoading: action.bound,
  planList: observable,
  clientSecret: observable,
  error: observable,
  loading: observable,
});

export default plan;
