import { Link } from "react-router-dom";
import styles from "./Header.module.scss";
import Logo from "./Logo/Logo";
import NavBlock from "./NavBlock/NavBlock";

const Header = () => {
  return (
    <header className={styles.header}>
      <div className={styles.inner}>
        <Link to="/">
          <Logo />
        </Link>
        <NavBlock />
      </div>
    </header>
  );
};

export default Header;
