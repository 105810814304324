import { useHistory, useLocation } from "react-router-dom";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";
import Table from "@mui/material/Table";

import { ReactComponent as TriangleIcon } from "../../assets/icons/double-triangle.svg";
import ControlButton from "../../components/ControlButtons/ControlButtons";
import DashboardMobile from "./DashboardMobile/DashboardMobile";
import { orderStatuses } from "../../helpers/contstants";
import { useStores } from "../../hooks/use-stores";
import styles from "./Dashboard.module.scss";
const queryString = require("query-string");
const tabs = Object.keys(orderStatuses);
const sliderOptions = {
  gap: "8px",
  type: "slide",
  arrows: false,
  autoWidth: true,
  rewind: false,
  pagination: false,
};

const Dashboard = () => {
  const {
    order: { getOrders, orders, ordersPerPageAmount, setOrderOnPageAmount, setOrders },
  } = useStores();
  const history = useHistory();
  const { search } = useLocation();
  const tableHeaderNode = useRef();
  const queryParams = queryString.parse(search, { arrayFormat: "comma" });

  const changeFilters = (tab) => () => {
    if (tab) {
      if (queryParams.filters?.includes(tab)) {
        if (typeof queryParams.filters === "string") queryParams.filters = undefined;
        else {
          const tabIndex = queryParams.filters.indexOf(tab);
          tabIndex > -1 && queryParams.filters.splice(tabIndex, 1);
        }
        if (queryParams.page) queryParams.page = undefined;
        history.push({ search: queryString.stringify(queryParams, { arrayFormat: "comma" }) });
      } else {
        if (!queryParams.filters) {
          queryParams.filters = tab;
        } else {
          if (typeof queryParams.filters === "string") queryParams.filters = [queryParams.filters, tab];
          else queryParams.filters.push(tab);
        }
        if (queryParams.page) queryParams.page = undefined;
        history.push({ search: queryString.stringify(queryParams, { arrayFormat: "comma" }) });
      }
    } else {
      if (queryParams.page) queryParams.page = undefined;
      queryParams.filters = undefined;
      history.push({ search: queryString.stringify(queryParams, { arrayFormat: "comma" }) });
    }
  };

  const changePaging = (event, number) => {
    queryParams.page = number + 1;
    history.push({ search: queryString.stringify(queryParams, { arrayFormat: "comma" }) });
  };

  const changeOrderAmoutOnPage = (event) => {
    queryParams.page = undefined;
    history.push({ search: queryString.stringify(queryParams, { arrayFormat: "comma" }) });
    setOrderOnPageAmount(event.target.value);
  };

  const sortOrder = (sortBy) => (event) => {
    const currentCell = event.target.parentElement;
    const cells = Array.from(event.target.parentElement.parentElement.children);
    const currentIcon = event.target.children[0];
    cells.forEach((cell) => {
      if (cell !== currentCell) {
        cell.children[1].children[0].classList.remove(styles.selected);
        cell.children[0].checked = false;
      }
    });
    currentIcon.classList.add(styles.selected);
    const isDescending = currentCell.children[0].checked;
    queryParams.sortDirection = isDescending ? "DESC" : "ASC";
    queryParams.sortField = sortBy;
    history.push({ search: queryString.stringify(queryParams, { arrayFormat: "comma" }) });
  };

  const onRedirect = (id) => {
    const path = `/order/${id}`;
    history.push(path);
  };

  useEffect(() => {
    queryParams.sortField &&
      tableHeaderNode.current.childNodes.forEach((cell) => {
        if (cell.id === queryParams.sortField) {
          cell.childNodes[1].childNodes[1].classList.add(styles.selected);
          if (queryParams.sortDirection === "DESC") cell.childNodes[0].checked = false;
          else cell.childNodes[0].checked = true;
        } else {
          cell.childNodes[1].childNodes[1].classList.remove(styles.selected);
          cell.childNodes[0].checked = false;
        }
      });
    getOrders(
      typeof queryParams.filters === "string" ? queryParams.filters : queryParams.filters?.join(),
      (Number(queryParams.page ?? 1) - 1) * ordersPerPageAmount,
      queryParams.sortField,
      queryParams.sortDirection,
    );
  }, [search, ordersPerPageAmount]);

  useEffect(() => () => setOrders(null), []);

  return (
    <section className={styles.wrapper}>
      <ControlButton redirectPage="catalogue" pageName="dashboard"/>

      <Splide className={styles.list} options={sliderOptions}>
        <SplideSlide className={`${styles.item} ${!queryParams.filters ? styles.active : ""} ${styles.disable}`} onClick={changeFilters()}>
          All
        </SplideSlide>
        {tabs.map((tab) => (
          <SplideSlide className={`${styles.item} ${queryParams.filters?.includes(tab) ? styles.active : ""}`} key={tab} onClick={changeFilters(tab)}>
            {orderStatuses[tab]}
          </SplideSlide>
        ))}
      </Splide>

      <div className={styles.mobile}>{orders?.results && <DashboardMobile data={orders.results} />}</div>
      <div className={styles.desktop}>
        <TableContainer>
          <Table className={styles.table} sx={{ width: 1 / 1 }}>
            <TableHead className={styles.heading}>
              <TableRow ref={tableHeaderNode}>
                <TableCell className={styles.cell} sx={{ width: 602 / 1290 }} id="name">
                  <input className={styles.input} type="checkbox" id="dashboard_name" />
                  <label htmlFor="dashboard_name" className={styles.label} onClick={sortOrder("name")}>
                    Order name
                    <TriangleIcon className={styles.icon} />
                  </label>
                </TableCell>
                <TableCell className={styles.cell} sx={{ width: 142 / 1290 }} id="id">
                  <input className={styles.input} type="checkbox" id="dashboard_id" />
                  <label className={styles.label} htmlFor="dashboard_id" onClick={sortOrder("id")}>
                    ID
                    <TriangleIcon className={styles.icon} />
                  </label>
                </TableCell>
                <TableCell className={styles.cell} sx={{ width: 328 / 1290 }} id="status">
                  <input className={styles.input} type="checkbox" id="dashboard_status" />
                  <label className={styles.label} htmlFor="dashboarqueryParamsd_status" onClick={sortOrder("status")}>
                    Status
                    <TriangleIcon className={styles.icon} />
                  </label>
                </TableCell>
                <TableCell className={styles.cell} sx={{ width: 215 / 1290 }} id="createdAt">
                  <input className={styles.input} type="checkbox" id="dashboard_date" />
                  <label className={styles.label} htmlFor="dashboard_date" onClick={sortOrder("createdAt")}>
                    Date
                    <TriangleIcon className={styles.icon} />
                  </label>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders?.results.length ? (
                orders.results.map((order) => (
                  <TableRow onClick={() => onRedirect(order.id)} className={styles.row} key={order.id}>
                    <TableCell component="th" className={styles.cell}>
                      {order.name}
                    </TableCell>
                    <TableCell component="th" className={styles.cell}>
                      {order.id}
                    </TableCell>
                    <TableCell component="th" className={styles.cell}>
                      {orderStatuses[order.status]}
                    </TableCell>
                    <TableCell component="th" className={styles.cell}>
                      {new Date(order.createdAt).toLocaleDateString("en-GB")}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow></TableRow>
              )}
            </TableBody>
            {orders?.metadata.total > ordersPerPageAmount && (
              <TableFooter>
                <TableRow>
                  <TablePagination
                    onRowsPerPageChange={changeOrderAmoutOnPage}
                    rowsPerPage={ordersPerPageAmount}
                    className={styles.pagination}
                    count={orders.metadata.total}
                    onPageChange={changePaging}
                    page={Number(queryParams.page ?? 1) - 1}
                  />
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      </div>
    </section>
  );
};

export default observer(Dashboard);
