import { observable, action, makeObservable } from "mobx";
import { axios } from "../utils/axios";
import file from "../api/endpoints/file";

class File {
  loading = false;
  error = null;

  async sendFile(form) {
    this.setLoadingStatus(true);
    this.setError(null);
    const path = file.fileUpload.path;
    const method = file.fileUpload.method;
    try {
      const resposne = await axios[method](path, form);
      return resposne;
    } catch (e) {
      this.setError("Failed to upload! Please contact support or try again later.");
      console.error(e);
    } finally {
      this.setLoadingStatus(false);
    }
  }

  async attachFiles(idArray, orderID) {
    this.setLoadingStatus(true);
    this.setError(null);
    const path = file.attach.path.replace(":id", orderID);
    const method = file.attach.method;
    try {
      const resposne = await axios[method](path, { files: idArray });
      return resposne;
    } catch (e) {
      this.setError("Failed to upload! Please contact support or try again later.");
      console.error(e);
    } finally {
      this.setLoadingStatus(false);
    }
  }

  async deleteFile(orderID) {
    this.setLoadingStatus(true);
    this.setError(null);
    const path = file.delete.path.replace(":id", orderID);
    const method = file.delete.method;
    try {
      const resposne = await axios[method](path);
      return resposne;
    } catch (e) {
      this.setError("Something went wrong! Please contact support or try again later.");
      console.error(e);
    } finally {
      this.setLoadingStatus(false);
    }
  }

  setLoadingStatus(status) {
    this.loading = status;
  }

  setError(error) {
    this.error = error;
  }
}

const fileStore = new File();

makeObservable(fileStore, {
  setLoadingStatus: action.bound,
  attachFiles: action.bound,
  deleteFile: action.bound,
  setError: action.bound,
  sendFile: action.bound,
  loading: observable,
  error: observable,
});

export default fileStore;
