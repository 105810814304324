import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import React from "react";

import Layout from "./components/Layout/Layout";
import App from "./App";
import "./index.scss";
import ReactGA from "react-ga4";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCjqqm0k32GE8Rawel8hDdAPApkKFnenOY",
  authDomain: "smart-drop-67cbd.firebaseapp.com",
  projectId: "smart-drop-67cbd",
  storageBucket: "smart-drop-67cbd.appspot.com",
  messagingSenderId: "141884467808",
  appId: "1:141884467808:web:e1b7070093775cd18f7105",
  measurementId: "G-QRY6CB5YJ6",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

ReactGA.initialize("G-CG5Y4Y3FB8");

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Layout>
        <App />
      </Layout>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root"),
);
