import axiosFactory from "axios";
import configuration, { storage } from "../api/config";
import store from "../store";

export const axios = axiosFactory.create({
  baseURL: configuration.baseUrl,
});

let tokenRefreshing = false;
let pendingRequests = [];

export const login = (axios.login = (creds) => {
  const data = {
    username: creds.username,
    password: creds.password,
  };
  return axios.post(configuration.tokenUrl, data);
});

export const logout = (axios.logout = () => {
  localStorage.removeItem(storage.userToken);
  localStorage.removeItem(storage.userRefreshToken);
});

const convertToURI = (data) => {
  let formBody = [];
  for (const property in data) {
    if (property) {
      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
  }
  formBody = formBody.join("&");
  return formBody;
};

axios.interceptors.request.use(setTokenParams);
axios.interceptors.request.use(setHeader);
axios.interceptors.request.use(setAuthHeader);
axios.interceptors.response.use(saveTokensFromLogin);
axios.interceptors.response.use(serializeData);
axios.interceptors.response.use(noop, considerRefreshTokenIfAuthFailed);
axios.interceptors.response.use(noop, adminSelfUpdate);

function setHeader(config) {
  if (config.url.includes("/token")) {
    config.headers["Content-Type"] = "application/x-www-form-urlencoded";
  } else if (config.url.includes("/file/upload")) {
    config.headers["Content-Type"] = "multipart/form-data";
  } else {
    config.headers["Content-Type"] = "application/json";
  }
  return config;
}

function setAuthHeader(config) {
  if (config.url !== configuration.tokenUrl && !config.url.includes("common")) {
    // eslint-disable-next-line camelcase
    const user_token = getToken(storage.userToken);
    // eslint-disable-next-line camelcase
    if (!user_token) return config;
    // eslint-disable-next-line camelcase
    config.headers["Authorization"] = `Bearer ${user_token}`;
  }

  return config;
}

function saveTokensFromLogin(response) {
  if (response.config.url === configuration.tokenUrl) {
    setTokens(response.data);
  }

  return response;
}

function setTokenParams(config) {
  if (config.url === configuration.tokenUrl) {
    const payload = {
      ...config.data,
      ...configuration.params,
      grant_type: config.data["refresh_token"] ? "refresh_token" : "password",
    };
    config.data = convertToURI(payload);
  }

  return config;
}

async function considerRefreshTokenIfAuthFailed(error) {
  if (error.response.status === 403) return Promise.reject(error);
  if (error.response.status === 400) return Promise.reject(error);

  if (error.config.url === configuration.tokenUrl) return;

  const {
    config,
    response: { status },
  } = error;
  const originalRequest = config;

  // Refresh token process is only considered for 401 errors, any others are skipped
  if (status === 403) {
    return Promise.reject(error);
  }

  // Refresh token process is only considered for 401 errors, any others are skipped
  if (status !== 401) {
    return Promise.reject(error);
  }

  // 401 error for a retried attempt with a presumably refreshed access token means something went wrong during refresh
  if (status === 401 && originalRequest.hasOwnProperty("retriedAttempt")) {
    axios.logout();
    store.authentication.setAuthenticated(false);
    return Promise.reject(error);
  }

  // This is to stop recursion if retried requests will fail with 401
  originalRequest["retriedAttempt"] = true;

  // eslint-disable-next-line camelcase
  const current_token = getToken(storage.userToken);
  // eslint-disable-next-line camelcase
  const request_token = originalRequest.headers.Authorization.split(" ")[1];

  // If a token from the original request differs from the current active token - try again, as the token was refreshed
  // eslint-disable-next-line camelcase
  if (current_token !== request_token) {
    return axios(originalRequest);
  }

  // retryRequest is a promise that will be resolved when a new access token is received, it's used to indicate that
  // the current request can be retried with a new access token.
  const retryRequest = new Promise((resolve, reject) => pendingRequests.push({ resolve: resolve, reject: reject }));

  await refreshToken();

  // Original request will be retried once a new access token is received
  return retryRequest.then(() => {
    return axios(originalRequest);
  });
}

async function refreshToken() {
  if (tokenRefreshing) return;

  tokenRefreshing = true;

  try {
    const refreshTokenData = await axios.post(configuration.tokenUrl, {
      refresh_token: window.localStorage.getItem(storage.userRefreshToken),
    });

    if (!refreshTokenData) {
      axios.logout();
      store.authentication.setAuthenticated(false);
    } else {
      setTokens(refreshTokenData);

      pendingRequests.forEach((resolver) => resolver.resolve());
    }
  } catch (refreshError) {
    pendingRequests.forEach((resolver) => resolver.reject());

    const status = refreshError.response.status;
    if (status === 400 || status === 401) {
      axios.logout();
      store.authentication.setAuthenticated(false);
    }
  } finally {
    tokenRefreshing = false;
    pendingRequests = [];
  }
}

async function adminSelfUpdate(error) {
  if (error.config.url === configuration.profileUrl && error.response.status === 422) {
    return Promise.reject(new Error("Error. Please, make sure this email isn't already used and your passwords are equal"));
  } else {
    return Promise.reject(error);
  }
}

function serializeData(response) {
  return response.data;
}

function noop(response) {
  return response;
}

// eslint-disable-next-line camelcase
function setTokens({ access_token, refresh_token }) {
  window.localStorage.setItem("access_token", access_token);
  window.localStorage.setItem("refresh_token", refresh_token);
}

export function getToken(tokenName) {
  return window.localStorage.getItem(tokenName);
}

export const getSavedUserToken = () => {
  return window.localStorage.getItem(storage.userToken) ? storage.userToken : false;
};
