import { Route, Switch, Router, Redirect } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { useStores } from "./hooks/use-stores";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
import ReactGA from "react-ga4";

import ShippingDeatilsUpdate from "./views/ShippingDeatilsUpdate/ShippingDeatilsUpdate";
import MainPageWrapper from "./components/MainPageWrapper/MainPageWrapper";
import SuccessPayment from "./views/SuccessPayment/SuccessPayment";
import ForgotPassword from "./views/ForgotPassword/ForgotPassword";
import Registration from "./views/Registration/Registration";
import ConfirmEmail from "./views/ConfirmEmail/ConfirmEmail";
import UserProfile from "./views/UserProfile/UserProfile";
import SetPassword from "./views/SetPassword/SetPassword";
import CreateOrder from "./views/CreateOrder/CreateOrder";
import FirstLogin from "./views/FirstLogin/FirstLogin";
import Dashboard from "./views/Dashboard/Dashboard";
import Catalog from "./views/Catalog/Catalog";
import Terms from "./views/Terms/Terms";
import Privacy from "./views/Privacy/Privacy";
import Pricing from "./views/Pricing/Pricing";
import Contact from "./views/Contact/Contact";
import AboutUs from "./views/AboutUs/AboutUs";
import Login from "./views/Login/Login";
import Order from "./views/Order/Order";
import Goods from "./views/Goods/Goods";

const App = () => {
  const [isAppLoaded, setAppLoadingStatus] = useState(false);

  const {
    authentication: { isAuthenticated, checkIfAuthenticated, logout },
    userDetails: { getUserProfile, isFirstLogin, identityError },
    messages: { getNotifications },
  } = useStores();
  const notificationTimer = useRef();
  const newHistory = useHistory();

  newHistory.listen((location) => {
    if (location.pathname == "/" || location.pathname == "/register") {
      ReactGA.event({
        category: "Main page active",
        action: "Redirect to main page",
      });
    };
    if (location.pathname == "/create-order") {
      ReactGA.event({
        category: "Offer page",
        action: "Offer page",
      });
    };
  });

  useEffect(() => isFirstLogin && newHistory.push("/first-login"), [isFirstLogin]);

  useEffect(() => identityError && logout(), [identityError]);

  useEffect(() => {
    checkIfAuthenticated();
    setAppLoadingStatus(true);
    const pageTitle = location.pathname.slice(1);
    ReactGA.send({ hitType: "pageview", page: pageTitle });
  }, []);

  useEffect(async () => {
    if (isAuthenticated) {
      if (window.location.href.includes("email-confirmation")) {
        newHistory.push(newHistory.location.pathname);
        logout();
        return;
      }
      await getUserProfile();
      await getNotifications();
      notificationTimer.current = setInterval(() => {
        getNotifications();
      }, 15000);
    } else {
      clearTimeout(notificationTimer.current);
    }
  }, [isAuthenticated]);

  return (
    isAppLoaded && (
      <Router history={newHistory}>
        {isAuthenticated ? (
          isFirstLogin === null ? (
            "Loading..."
          ) : (
            <Switch>
              <Route path="/shipping-details-update/:id" component={ShippingDeatilsUpdate} />
              <Route path="/success-payment" component={SuccessPayment} />
              <Route path="/user-profile" component={UserProfile} />
              <Route path="/create-order" component={CreateOrder} />
              <Route path="/first-login" component={FirstLogin} />
              <Route path="/dashboard" component={Dashboard} />
              <Route path="/catalogue" component={Catalog} />
              <Route path="/about-us" component={AboutUs} />
              <Route path="/terms" component={Terms} />
              <Route path="/privacy" component={Privacy} />
              <Route path="/pricing" component={Pricing} />
              <Route path="/contact" component={Contact} />
              <Route path="/order/:id" component={Order} />
              <Route path="/goods/:id" component={Goods} />
              <Route path="/login" component={Login} />
              <Route path="/" component={Registration} />
              <Redirect to="/dashboard" />
            </Switch>
          )
        ) : (
          <MainPageWrapper>
            <Switch>
              <Route path="/email-confirmation" component={ConfirmEmail} />
              <Route path="/forgot-password" component={ForgotPassword} />
              <Route path="/about-us" component={AboutUs} />
              <Route path="/set-password" component={SetPassword} />
              <Route path="/terms" component={Terms} />
              <Route path="/privacy" component={Privacy} />
              <Route path="/pricing" component={Pricing} />
              <Route path="/contact" component={Contact} />
              <Route path="/login" component={Login} />
              <Route path="/" component={Registration} />
              <Route path="/register" component={Registration} />
              <Redirect to="/" />
            </Switch>
          </MainPageWrapper>
        )}
      </Router>
    )
  );
};

export default observer(App);
