import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";

import ControlButton from "../../components/ControlButtons/ControlButtons";
import CategoryTable from "../../components/CategoryTable/CategoryTable";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import { useStores } from "../../hooks/use-stores";
import styles from "./Catalog.module.scss";
const queryString = require("query-string");

const Catalog = () => {
  const {
    catalog: { getCatalogItem, viewCatalog, setCatalogItem },
  } = useStores();
  const { search } = useLocation();
  const queryParams = queryString.parse(search, { arrayFormat: "comma" });

  useEffect(() => {
    if (queryParams.category) {
      getCatalogItem(queryParams.category);
      viewCatalog(queryParams.category);
    } else {
      getCatalogItem(1);
      viewCatalog(1);
    }
  }, [search]);

  useEffect(() => () => setCatalogItem(null), []);

  return (
    <section className={styles.wrapper}>
      <ControlButton redirectPage="dashboard" pageName="catalogue" isReverse />
      <BreadCrumbs />
      <CategoryTable />
    </section>
  );
};

export default observer(Catalog);
