import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import moment from "moment";
import PropTypes from "prop-types";

import styles from "./FileList.module.scss";

const FilesList = ({ order }) => {
  const files = [
    ...(order.productDetailsFile ? [order.productDetailsFile] : []),
    ...(order.productShipmentFile ? [order.productShipmentFile] : []),
    ...(order.messagesFiles ? order.messagesFiles.map((file) => file.file) : []),
  ].sort((a, b) => {
    if (moment(a.createdAt).isAfter(b.createdAt)) {
      return 1;
    }
    if (moment(a.createdAt).isBefore(b.createdAt)) {
      return -1;
    }
    return 0;
  });

  return (
    <ul className={styles.wrapper}>
      {files.map((file) => (
        <a className={styles.link} key={file.url} href={file.url} target="_blank" rel="noreferrer">
          <InsertDriveFileOutlinedIcon className={styles.icon} fontSize="string" /> {file.file.originalName}
        </a>
      ))}
    </ul>
  );
};

FilesList.propTypes = {
  order: PropTypes.object.isRequired,
};

export default FilesList;
