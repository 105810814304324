import TextareaAutosize from "@mui/material/TextareaAutosize";
import { TextField } from "@mui/material";
import PropTypes from "prop-types";

import styles from "./CargoDetails.module.scss";

const CargoDetails = ({ isSubmitted, errors, register }) =>
  <>
    <h3>Cargo details</h3>
    <div className={styles.wrapper}>
      <TextField
        helperText={isSubmitted && errors.placeOfLoading ? errors.placeOfLoading.message : " "}
        color={`${isSubmitted ? "success" : "grey"}`}
        error={isSubmitted && !!errors.placeOfLoading}
        label="Place of loading*"
        {...register("placeOfLoading")}
        name="placeOfLoading"
        variant="filled"
      />
      <TextField
        helperText={isSubmitted && errors.commodity ? errors.commodity.message : " "}
        color={`${isSubmitted ? "success" : "grey"}`}
        error={isSubmitted && !!errors.commodity}
        label="Commodity*"
        {...register("commodity")}
        name="commodity"
        variant="filled"
      />
      <TextField
        helperText={isSubmitted && errors.placeOfDischarge ? errors.placeOfDischarge.message : " "}
        color={`${isSubmitted ? "success" : "grey"}`}
        error={isSubmitted && !!errors.placeOfDischarge}
        label="Place of discharge*"
        {...register("placeOfDischarge")}
        name="placeOfDischarge"
        variant="filled"
      />
      <TextField
        helperText={isSubmitted && errors.shippingMethod ? errors.shippingMethod.message : " "}
        error={isSubmitted && !!errors.shippingMethod}
        color={`${isSubmitted ? "success" : "grey"}`}
        label="Shipping method*"
        {...register("shippingMethod")}
        name="shippingMethod"
        variant="filled"
      />
      <TextField
        helperText={isSubmitted && errors.incoterms ? errors.incoterms.message : " "}
        color={`${isSubmitted ? "success" : "grey"}`}
        error={isSubmitted && !!errors.incoterms}
        label="Incoterms*"
        {...register("incoterms")}
        name="incoterms"
        variant="filled"
      />
      <TextareaAutosize
        placeholder="Max length: 500 symbol"
        className="global-textarea"
        aria-label="maximum height"
        {...register("remarks")}
        maxLength={500}
        minRows={4}
        maxRows={4}
      />
    </div>
  </>;

CargoDetails.propTypes = {
  isSubmitted: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
};

export default CargoDetails;
