import { useHistory, Link } from "react-router-dom";
import { useStores } from "../../hooks/use-stores";
import { useEffect, useLayoutEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import ReactGA from "react-ga4";

import styles from "./ConfirmEmail.module.scss";

const ConfirmEmail = () => {
  const {
    authentication: { error, confirmEmail, clearError, changeEmail, logout, setError },
  } = useStores();
  const [isChecked, setCheckingStatus] = useState(false);
  const history = useHistory();

  useEffect(() => {
    logout();
    (async () => {
      const token = window.location.pathname.split("/")[2];
      if (window.location.search && window.location.search === "?type=email-changed") {
        await changeEmail(token);
        setCheckingStatus(true);
        return;
      }
      await confirmEmail(token);
      setCheckingStatus(true);
    })();
    return () => setError(null);
  }, []);

  useEffect(
    () => {
      isChecked && !error &&
      history.push({
        pathname: "/login",
        state: { emailConfirmationMessage: "Your email has been successfully confirmed! Now you can login with your credentials." },
      });
      ReactGA.event({
        category: "Confirm mail",
        action: "Confirm mail",
      });
    }, [isChecked],
  );

  return (
    <>
      <p className={`${error ? styles.error : styles.notification}`}>{error ?? "Checking..."}</p>
      {error && (
        <Link className={`global-link ${styles.link}`} to="/register" onClick={clearError}>
          Try register again
        </Link>
      )}
    </>
  );
};

export default observer(ConfirmEmail);
