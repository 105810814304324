import { Link, useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { ReactComponent as DashboardIcon } from "../../assets/icons/dashboard.svg";
import { ReactComponent as FolderIcon } from "../../assets/icons/folder.svg";
import { ReactComponent as FileIcon } from "../../assets/icons/file.svg";
import { useStores } from "../../hooks/use-stores";
import styles from "./CategoryTable.module.scss";

const CategoryTable = () => {
  const {
    catalog: { catalogList, catalogItem },
  } = useStores();
  catalogList.map((category) => console.log(category, category.file, category.id));

  return (
    <>
      <h4 className={`${styles.title} ${styles.item}`}>{catalogItem?.id === 1 ? "All" : catalogItem?.name}</h4>
      <ul className={styles.wrapper}>
        {catalogList.map((category) => (
          <Link className={styles.item} to={category.file ? `/goods/${category.id}` : `/catalogue?category=${category.id}`} key={category.id}>
            {category.type === "category" ? <FolderIcon /> : <FileIcon />}
            {category.name}
          </Link>
        ))}
      </ul>
      {catalogList.length === 0 && <p className={styles.notification}>There no any goods yet</p>}

      {catalogItem?.id !== 1 && (
        <Link className={styles.link} to="/catalogue">
          <DashboardIcon className={styles.icon} /> all categories
        </Link>
      )}
    </>
  );
};

export default observer(CategoryTable);
