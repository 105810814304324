import styles from "./CallButtons.module.scss";
import AuthenticationMenu from "../AuthenticationMenu/AuthenticationMenu";
import Burger from "./Burger/Burger";
import PropTypes from "prop-types";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import Popover from "@mui/material/Popover";
import { NavLink } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { useStores } from "../../../hooks/use-stores";
import { observer } from "mobx-react-lite";

const CallButtons = ({ onToggle, closeMobileNavigation, isMenuOpen }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const {
    authentication: { isAuthenticated },
    messages: { unreadList },
  } = useStores();

  const ref = useRef(null);

  const handleClick = (event) => {
    if (!unreadList.totalCount) return;
    setAnchorEl(event.currentTarget);
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      handleClose();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <div className={styles.CallButtons}>
      {isAuthenticated && (
        <div aria-describedby={id} className={styles.wrap} onClick={handleClick} ref={ref}>
          {unreadList && unreadList.totalCount > 0 && (
            <div className={styles.counter}>
              {unreadList.totalCount}
            </div>
          )}
          {/* <MailOutlineIcon sx={{
            color: "#A8ADAF",
            position: "relative",
            cursor: "pointer",
            transition: "all 0.3s ease",
            "&:hover": {
              color: "#51b449",
              transition: "all 0.3s ease",
            },
          }} /> */}
        </div>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{
          padding: "15px",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className={styles.popoverInner}>
          {unreadList.notifications && unreadList.notifications.map((el, index) => {
            return (
              <div key={index} className={styles.linkWrap}>
                <NavLink className={styles.link} to={`/order/${el.orderId}`}>
                  <span className={styles.orderName}>{el.orderName}</span>
                  <span>{el.count}</span>
                </NavLink>
              </div>
            );
          })}
        </div>
      </Popover>
      <AuthenticationMenu closeMobileNavigation={closeMobileNavigation}/>
      <Burger onBurgerClick={onToggle} isMenuOpen={isMenuOpen}/>
    </div>
  );
};

export default observer(CallButtons);

CallButtons.propTypes = {
  closeMobileNavigation: PropTypes.func,
  onToggle: PropTypes.func,
  isMenuOpen: PropTypes.bool,
};
