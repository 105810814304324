import TextareaAutosize from "@mui/base/TextareaAutosize";
import { observer } from "mobx-react-lite";
import { useRef, useState } from "react";
import PropTypes from "prop-types";

import { ReactComponent as PlaneIcon } from "../../assets/icons/plane.svg";
import FileUploader from "../FileUploader/FileUploader";
import { useStores } from "../../hooks/use-stores";
import styles from "./MessageSender.module.scss";
import ReactGA from "react-ga4";

const MessageSender = ({ orderID, messageListNode }) => {
  const uploaderNode = useRef();
  const [message, setMessage] = useState("");
  const {
    messages: { getMessages, sendMessage },
    order: { getCurrentOrder },
  } = useStores();

  const messageSend = async () => {
    const filesInfo = uploaderNode.current.getFilesInfo();
    if (filesInfo.length > 0) {
      for (const file of filesInfo) {
        const payload = {
          message: file.name,
          order: {
            id: orderID,
          },
          fileId: file.id,
        };
        await sendMessage(payload);
      }
      await getCurrentOrder(orderID);
      await getMessages(orderID);
      uploaderNode.current.clearFilesInfo();
    }
    const payload = {
      message: message.trim(),
      order: {
        id: orderID,
      },
    };
    setMessage("");
    if (message) {
      await sendMessage(payload);
      getMessages(orderID);
      ReactGA.event({
        category: "Chat activation",
        action: "Chat activation",
      });
    }
    messageListNode.current.scrollTo(0, 0);
  };

  const analyzePress = (event) => {
    if (event.ctrlKey && event.key === "Enter") {
      setMessage((message) => message + "\n");
    } else if (event.key === "Enter") {
      event.preventDefault();
      messageSend();
    }
  };
  const onChange = (e) => setMessage(e.target.value);

  return (
    <div className={styles.wrapper}>
      <FileUploader inputID="messenger-file-uploader" className={styles.uploader} ref={uploaderNode} withCounter withoutName isMultiple />
      <TextareaAutosize
        placeholder="Write a message..."
        aria-label="maximum height"
        className={styles.input}
        onKeyDown={analyzePress}
        onChange={onChange}
        value={message}
        maxRows={10}
        minRows={1}
      />
      <PlaneIcon className={styles.button} onClick={messageSend} />
    </div>
  );
};

MessageSender.propTypes = {
  messageListNode: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
  orderID: PropTypes.number.isRequired,
};

export default observer(MessageSender);
