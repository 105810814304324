import PropTypes from "prop-types";

import { ReactComponent as CrossIcon } from "../../assets/icons/cross-icon.svg";
import styles from "./Alert.module.scss";

const Alert = ({ close, children, className }) => {
  return (
    <article className={`${styles.wrapper} ${className}`}>
      <button className={styles.cross} onClick={close}>
        <CrossIcon />
      </button>
      {children}
    </article>
  );
};

Alert.propTypes = {
  close: PropTypes.func.isRequired,
  className: PropTypes.string,
  children: PropTypes.element,
};

export default Alert;
