import { Link, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { useStores } from "../../hooks/use-stores";
import styles from "./Registration.module.scss";
import Form from "../../components/Form/Form";
import Modal from "@mui/material/Modal";
import CancelMySubscription from "../UserProfile/CancelMySubscription/CancelMySubscription";
import { func } from "prop-types";
import ReactGA from "react-ga4";

/* eslint-disable */

const Registration = () => {
  const { authentication: { registerUser, login, setFirstLogin, isFirstLogin, error, clearError, emailConfirmationToken, isLoading } } = useStores();
  const [isWelcomeSectionVisible, setWelcomeSectionVisivility] = useState(true);
  const [wasFetch, setFetchStatus] = useState(false);
  const [modalActive, setModalActive] = useState(false);
  const query = useHistory();

  useEffect(() => {
    () => clearError();
    if(query.location.search) {
      setWelcomeSectionVisivility(false)
    };
    if(isFirstLogin) {
      setWelcomeSectionVisivility(false);
      setModalActive(true)
    }
  }, []);

  useEffect(() => {
    if(emailConfirmationToken && wasFetch) {
      window.gtag("event", ({
        category: "Popup registration completed",
        action: "Popup registration completed",
      }));
    };
  }, [emailConfirmationToken, wasFetch]);

  const sendUserDataToApi = async (userData) => {
    await registerUser({
      email: userData.email,
      fullName: userData.name,
      password: userData.password,
      phone: userData.phone,
      captchaCode: userData.captchaCode,
      local: true
    });
    await login({
      username: userData.email,
      password: userData.password,
    });
    setFetchStatus(true);
    if(emailConfirmationToken) ReactGA.event({
      category: "Registration completed",
      action: "Registration completed",
    });

  };

  function handleClose() {
    query.push({pathname: "/first-login"});
    setModalActive(false);
    setFirstLogin(false);
    setWelcomeSectionVisivility(true);
  };

  function redirectToMain() {
    query.push({pathname: "/first-login"});
    setModalActive(false);
    setFirstLogin(false);
    setWelcomeSectionVisivility(true);
  };

  function registerBtn() {
    setWelcomeSectionVisivility(false);
    ReactGA.event({
      category: "Customer registration",
      action: "Customer registration",
    });
  };

  return (
    <>
    <div className={styles.wrapper}>
      {isWelcomeSectionVisible ? (
        <>
          <p className={styles.invite}>Join us now and be a part of something bigger</p>
          <div className={styles.receptacle}>
            <button className={`${styles.button} global-button`} onClick={registerBtn}>
              Register
            </button>
            <Link className={`${styles.link} global-link`} to="/login">
              I already have account
            </Link>
          </div>
        </>
      ) : (
        <>
          {isFirstLogin ? (
            <>
              <p className={styles.advice}>We have sent an email to your address. Please check it and follow the instruction</p>
              <Modal className={styles.modal} open={modalActive} >
                <CancelMySubscription
                  head={<span>Thank you for registering, let's pick a package for you!</span>}
                  content={<span className={styles.modalInfo}>You can try for free for 7 days.</span>}
                  onCancel={handleClose}
                  onAccept={redirectToMain}
                  action={"Let's get started!"}
                />
              </Modal>
            </> 
          ) : (
            <>
              <p className={styles.subtitle}>Join us now</p>
              <Form
                className={styles.form}
                type="register"
                dataHandler={sendUserDataToApi}
                apiErrorMessage={error}
                loading={isLoading}
                link={
                  <Link className={`global-link ${styles.link}`} to="/login">
                    I already have account
                  </Link>
                }
              />
              <p className={styles.notice}>
                All fields required
                <br />
                Password should be 8 symbols at least
              </p>
            </>
          )}
        </>
      )}
    </div>
    </>
  );
};

export default observer(Registration);
