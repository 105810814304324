import FileUploader from "../FileUploader/FileUploader";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState, useEffect, useRef } from "react";
import TextField from "@mui/material/TextField";
import { observer } from "mobx-react-lite";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import * as Yup from "yup";

import SuccessMessage from "../SuccessMessage/SuccessMessage";
import { emailRegExp } from "../../helpers/contstants";
import styles from "./ProductShipment.module.scss";
import ConsigneeDetails from "./ConsigneeDetails";
import ShipperDetails from "./ShipperDetails";
import CargoDetails from "./CargoDetails";
import ReactGA from "react-ga4";
/* eslint-disable */

const validationSchema = Yup.object().shape({
  consigneeEmail: Yup.string()
    .required("Consignee email is required")
    .matches(emailRegExp, { message: "enter a valid email before and after '@'", excludeEmptyString: true }),
  shipperEmail: Yup.string()
    .required("Shipper email is required")
    .matches(emailRegExp, { message: "enter a valid email before and after '@'", excludeEmptyString: true }),
  consigneeCountry: Yup.string().required("Consignee country is required"),
  shipperCountry: Yup.string().required("Shipper country is required"),
  consigneeContactDestination: Yup.string()
    .required("Consignee contact destination is required")
    .min(2, "Consignee contact destination required must be at least 2 characters")
    .max(150, "Consignee contact destination must not exceed 150 characters"),
  shipperName: Yup.string()
    .required("Shipper name required")
    .min(2, "Shipper name required must be at least 2 characters")
    .max(150, "Shipper name must not exceed 150 characters"),
  shipperContactPerson: Yup.string()
    .required("Shipper contact person required")
    .min(2, "Shipper contact person required must be at least 2 characters")
    .max(150, "Shipper contact person must not exceed 150 characters"),
  shipperPhone: Yup.string()
    .required("Shipper phone person required")
    .min(2, "Shipper phone person required must be at least 2 characters")
    .max(15, "Shipper phone person must not exceed 15 characters"),
  shipperContactDestination: Yup.string()
    .required("Shipper contact is required")
    .min(2, "Shipper contact required must be at least 2 characters")
    .max(150, "Shipper contact must not exceed 150 characters"),
  shipperPostcode: Yup.string().required("Shipper post code is required"),
  shipperState: Yup.string()
    .required("Shipper state is required")
    .min(2, "Shipper state required must be at least 2 characters")
    .max(100, "Shipper state must not exceed 100 characters"),
  shipperCity: Yup.string()
    .required("Shipper city is required")
    .min(2, "Shipper city required must be at least 2 characters")
    .max(100, "Shipper city must not exceed 100 characters"),
  consigneeName: Yup.string()
    .required("Consignee name is required")
    .min(2, "Consignee name required must be at least 2 characters")
    .max(150, "Consignee name must not exceed 150 characters"),
  consigneeContactPerson: Yup.string()
    .required("Consignee contact person is required")
    .min(2, "Consignee contact person required must be at least 2 characters")
    .max(150, "Consignee contact person must not exceed 150 characters"),
  consigneePhone: Yup.string()
    .required("Consignee phone is required")
    .min(1, "Consignee phone required must be at least 1 characters")
    .max(15, "Consignee phone must not exceed 15 characters"),
  consigneePostcode: Yup.string().required("Consignee post code is required"),
  consigneeState: Yup.string()
    .required("Consignee state is required")
    .min(2, "Consignee state required must be at least 2 characters")
    .max(100, "Consignee state must not exceed 100 characters"),
  consigneeCity: Yup.string()
    .required("Consignee city is required")
    .min(2, "Consignee city required must be at least 2 characters")
    .max(100, "Consignee city must not exceed 100 characters"),
  placeOfLoading: Yup.string()
    .required("Place of loading is required")
    .min(2, "Place of loading required must be at least 2 characters")
    .max(150, "Place of loading must not exceed 150 characters"),
  placeOfDischarge: Yup.string()
    .required("Place of discharge is required")
    .min(2, "Place of discharge required must be at least 2 characters")
    .max(150, "Place of discharge must not exceed 150 characters"),
  name: Yup.string()
    .required("Shipper name required")
    .min(2, "Shipper name required must be at least 2 characters")
    .max(150, "Shipper name must not exceed 150 characters"),
  incoterms: Yup.string()
    .required("Incoterms is required")
    .min(2, "Incoterms must be at least 2 characters")
    .max(150, "Incoterms must not exceed 150 characters"),
  commodity: Yup.string()
    .required("Commodity is required")
    .min(2, "Commodity must be at least 2 characters")
    .max(150, "Commodity must not exceed 150 characters"),
  shippingMethod: Yup.string()
    .required("Shipping method is required")
    .min(2, "Shipping method must be at least 2 characters")
    .max(150, "Shipping method must not exceed 150 characters"),
  remarks: Yup.string(),
});

const ProductShipment = ({ formDataHandler, isLoading, defaultFormData, error, update, catalogFile }) => {
  const [isSuccess, setSuccessStatus] = useState(false);
  const uploaderNode = useRef();
  const {
    formState: { errors, isSubmitted },
    handleSubmit,
    register,
    reset,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    defaultFormData && reset(defaultFormData);
    setTimeout(() => {
      reset(defaultFormData);
    }, 1000);
  }, [defaultFormData]);

  const onSuccess = () => setSuccessStatus(true);

  function createGE() {
    ReactGA.event({
      category: "Creating an order",
      action: "Creating an order",
    });
  };

  return (
    <form className={styles.wrapper} onSubmit={handleSubmit((data) => formDataHandler(data, onSuccess, uploaderNode.current.getFilesInfo()))}>
      <TextField
        helperText={isSubmitted && errors.name ? errors.name.message : " "}
        color={`${isSubmitted ? "success" : "grey"}`}
        disabled={Boolean(defaultFormData?.name)}
        error={isSubmitted && !!errors.name}
        className={styles.field}
        {...register("name")}
        label="Order name*"
        variant="filled"
        name="name"
      />
      <ShipperDetails register={register} isSubmitted={isSubmitted} errors={errors} />
      <ConsigneeDetails register={register} isSubmitted={isSubmitted} errors={errors} />
      <CargoDetails register={register} isSubmitted={isSubmitted} errors={errors} />
      <FileUploader inputID="product-shipment-file-uploader" ref={uploaderNode} catalogFile={catalogFile}/>
      <button className="global-button" disabled={isLoading} onClick={createGE}>
        {update ? "Update details" : "Create order"}
      </button>
      {isSuccess && <SuccessMessage message={"Your request was successfully send"} className={styles.notification} />}
      {error && <p className="global-error">{error}</p>}
    </form>
  );
};

ProductShipment.propTypes = {
  formDataHandler: PropTypes.func.isRequired,
  defaultFormData: PropTypes.object,
  isLoading: PropTypes.bool,
  error: PropTypes.string,
  update: PropTypes.bool,
  catalogFile: PropTypes.shape({
    goodsName: PropTypes.string,
    name: PropTypes.string,
    url: PropTypes.string,
    id: PropTypes.string,
  }),
};

export default observer(ProductShipment);
