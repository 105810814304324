import authentication from "./authentication";
import userDetails from "./user-details";
import messages from "./messages";
import catalog from "./catalog";
import order from "./order";
import plan from "./plan";
import file from "./file";

export default {
  authentication,
  userDetails,
  messages,
  catalog,
  order,
  plan,
  file,
};
