import { ReactComponent as BucketIcon } from "../../assets/icons/bucket.svg";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import { yupResolver } from "@hookform/resolvers/yup";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Checkbox from "@mui/material/Checkbox";
import { observer } from "mobx-react-lite";
import DatePicker from "@mui/lab/DatePicker";
import { useState, useRef, useEffect } from "react";
import { TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { useStores } from "../../hooks/use-stores";
import { toJS } from "mobx";

import styles from "./CompanyDetailsForm.module.scss";
import SuccessMessage from "../SuccessMessage/SuccessMessage";

const validationSchema = Yup.object().shape({
  pan: Yup.string().required("This field is required").min(8, "PAN must be at least 8 characters").max(8, "PAN must not exceed 8 characters"),
  eori: Yup.string()
    .required("This field is required")
    .matches(/[a-zA-Z]{2}[0-9]{12}$/, "EORI must be 2 characters followed by 12 digits"),
  vat: Yup.string()
    .required("This field is required")
    .min(1, "VAT must be at least 1 character"),
  natureOfBusiness: Yup.string()
    .required("This field is required")
    .min(2, "Nature of business must be at least 2 characters")
    .max(150, "Nature of business must not exceed 150 characters"),
  customerName: Yup.string()
    .required("This field is required")
    .min(2, "Name and address must be at least 2 characters")
    .max(150, "Name and address must not exceed 150 characters"),
  customerAddress: Yup.string()
    .required("This field is required")
    .min(2, "Name and address must be at least 2 characters")
    .max(150, "Name and address must not exceed 150 characters"),
  directorName: Yup.string(),
  constitution: Yup.string()
    .required("This field is required")
    .min(2, "Pvt. Ltd. must be at least 2 characters")
    .max(150, "Pvt. Ltd. must not exceed 150 characters"),
  establishedDate: Yup.string()
    .required("This field is required")
    .transform((_, value) => {
      return value.slice(0, 4);
    })
    .typeError("you must specify a number")
    .test("len", "Must be exactly 4 characters", (val) => val.toString().slice(0, 4).length === 4),
  branchOffices: Yup.string(),
  tax: Yup.string(),
});

const CompanyDetailsForm = ({ formHandler, type, defaultFormData = {}, isLoading, error, onAccountDelete, isInfoApproved }) => {
  const {
    userDetails: { setPANCompanies, getPANCompanies, PANCompanies },
  } = useStores();
  const [year, setYear] = useState(defaultFormData?.establishedDate ?? null);
  const [isSuccess, setSuccessStatus] = useState(false);
  const [currentType, setCurrentType] = useState("");
  const {
    formState: { errors, isSubmitted },
    handleSubmit,
    register,
    resetField,
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: { ...defaultFormData },
    resolver: yupResolver(validationSchema),
  });

  const ref = useRef(null);

  useEffect(() => {
    if (defaultFormData[Object.keys(defaultFormData)[0]]) {
      const { establishedDate, ...restDefaultFormData } = defaultFormData;
      setYear(establishedDate.toString());
      reset(restDefaultFormData);
    }
  }, [defaultFormData]);

  const onSuccess = () => {
    setSuccessStatus(true);
    setTimeout(() => setSuccessStatus(false), 4000);
  };

  const delay = (() => {
    let timer = 0;
    return (callback, ms) => {
      clearTimeout(timer);
      timer = setTimeout(callback, ms);
    };
  })();

  const getPAN = (data, type) => {
    setCurrentType(type);
    delay(() => getPANCompanies(data), 1000);
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      handleClose();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const handleClose = () => {
    setPANCompanies([]);
  };

  const onSelectCompany = (cmp) => {
    const data = toJS(cmp);
    resetField("customerName", {
      defaultValue: data.title,
      keepDirty: true,
    });
    resetField("customerAddress", {
      defaultValue: data.address,
      keepDirty: true,
    });
    resetField("constitution", {
      defaultValue: data.companyType,
      keepDirty: true,
    });
    resetField("pan", {
      defaultValue: data.companyNumber,
      keepDirty: true,
    });
    resetField("establishedDate", {
      defaultValue: data.dateOfCreation.slice(0, 4),
      keepDirty: true,
    });
    setYear(data.dateOfCreation.slice(0, 4));
    setPANCompanies([]);
  };

  return (
    <>
      <form className={`${styles.wrapper} ${isInfoApproved ? styles.extended : ""}`} onSubmit={handleSubmit((data) => formHandler(data, onSuccess))}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <div className={styles.panWrap}>
            <TextField
              label="Name of the customer*"
              helperText={isSubmitted && errors.customerName ? errors.customerName.message : " "}
              color={`${isSubmitted ? "success" : "grey"}`}
              error={isSubmitted && errors.customerName}
              {...register("customerName")}
              name="customerName"
              variant="filled"
              InputLabelProps={{ shrink: true }}
              defaultValue={type === "create" ? "" : " "}
              onChange={(e) => {
                getPAN(e.target.value, "name");
              }}
            />
            <TextField
              helperText={isSubmitted && errors.customerAddress ? errors.customerAddress.message : " "}
              color={`${isSubmitted ? "success" : "grey"}`}
              error={isSubmitted && errors.customerAddress}
              label="Address of the customer*"
              {...register("customerAddress")}
              name="customerAddress"
              variant="filled"
              sx={{
                width: "100%",
              }}
              InputLabelProps={{ shrink: true }}
            />
            {PANCompanies.length && currentType === "name" ? (
              <ul className={styles.panList} ref={ref}>
                {PANCompanies.map((el, index) => {
                  return (
                    <li onClick={() => onSelectCompany(el)} key={index}>
                      {el.title}
                    </li>
                  );
                })}
              </ul>
            ) : null}
          </div>
          <TextField
            label="Constitution (Pvt. Ltd., LTD., Partnership, Proprietorship)*"
            helperText={isSubmitted && errors.constitution ? errors.constitution.message : " "}
            color={`${isSubmitted ? "success" : "grey"}`}
            error={isSubmitted && errors.constitution}
            {...register("constitution")}
            name="constitution"
            variant="filled"
            InputLabelProps={{ shrink: true }}
            defaultValue={type === "create" ? "" : " "}
          />
          <div className={styles.panWrap}>
            <TextField
              sx={{
                width: "100%",
              }}
              helperText={isSubmitted && errors.pan ? errors.pan.message : " "}
              color={`${isSubmitted ? "success" : "grey"}`}
              label="Permenant account No. (PAN)*"
              error={isSubmitted && errors.pan}
              {...register("pan")}
              variant="filled"
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {
                getPAN(e.target.value, "pan");
              }}
              defaultValue={type === "create" ? "" : " "}
              name="pan"
            />
            {PANCompanies.length && currentType === "pan" ? (
              <ul className={styles.panList} ref={ref}>
                {PANCompanies.map((el, index) => {
                  return (
                    <li onClick={() => onSelectCompany(el)} key={index}>
                      {el.title}
                    </li>
                  );
                })}
              </ul>
            ) : null}
          </div>
          <DatePicker
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                InputLabelProps={{ shrink: true }}
                label="Date of Establishment*"
                {...register("establishedDate")}
                error={isSubmitted && errors.establishedDate}
                color={`${isSubmitted ? "success" : "grey"}`}
                helperText={isSubmitted && errors.establishedDate?.message}
                className={styles.picker}
                sx={{ marginBottom: 3 }}
              />
            )}
            onChange={(chosenYear) => setYear(chosenYear)}
            views={["year"]}
            value={year}
          />
          <TextField
            helperText={isSubmitted && errors.vat ? errors.vat.message : " "}
            label="Value Added Tax Registration No. (VAT or N/A)*"
            color={`${isSubmitted ? "success" : "grey"}`}
            error={isSubmitted && errors.vat}
            {...register("vat")}
            InputLabelProps={{ shrink: true }}
            defaultValue={type === "create" ? "" : " "}
            variant="filled"
            name="vat"
          />
          <TextField
            label="Economic Operators Registation and Identification number (EORI)*"
            helperText={isSubmitted && errors.eori ? errors.eori.message : " "}
            color={`${isSubmitted ? "success" : "grey"}`}
            error={isSubmitted && errors.eori}
            {...register("eori")}
            InputLabelProps={{ shrink: true }}
            defaultValue={type === "create" ? "" : " "}
            variant="filled"
            name="eori"
          />
          <TextField
            helperText={isSubmitted && errors.natureOfBusiness ? errors.natureOfBusiness.message : " "}
            error={isSubmitted && errors.natureOfBusiness}
            color={`${isSubmitted ? "success" : "grey"}`}
            {...register("natureOfBusiness")}
            label="Nature of Business*"
            name="natureOfBusiness"
            InputLabelProps={{ shrink: true }}
            defaultValue={type === "create" ? "" : " "}
            variant="filled"
          />
          <TextField
            helperText={isSubmitted && errors.directorName ? errors.directorName.message : " "}
            label="Contact person details (name, email, phone number)*"
            color={`${isSubmitted ? "success" : "grey"}`}
            error={isSubmitted && errors.directorName}
            {...register("directorName")}
            InputLabelProps={{ shrink: true }}
            name="directorName"
            defaultValue={type === "create" ? "" : " "}
            variant="filled"
          />
          {isInfoApproved && (
            <>
              <TextField
                helperText={isSubmitted && errors.branchOffices ? errors.branchOffices.message : " "}
                label="Details of branch offices of the company"
                color={`${isSubmitted ? "success" : "grey"}`}
                error={isSubmitted && errors.branchOffices}
                {...register("branchOffices")}
                InputLabelProps={{ shrink: true }}
                name="branchOffices"
                defaultValue={type === "create" ? "" : " "}
                variant="filled"
              />
              <TextField
                helperText={isSubmitted && errors.tax ? errors.tax.message : " "}
                label="Contact person details (name, email, phone number)"
                color={`${isSubmitted ? "success" : "grey"}`}
                error={isSubmitted && errors.tax}
                {...register("tax")}
                InputLabelProps={{ shrink: true }}
                name="tax"
                defaultValue={type === "create" ? "" : " "}
                variant="filled"
              />
            </>
          )}
          {type === "create" && (
            <>
              <FormControlLabel
                control={<Checkbox {...register("checkbox")} color="success" sx={{ ...(isSubmitted && errors.checkbox?.message && { color: "#e10000" }) }} />}
                label="''I hereby declare the above information is true & correct''"
                className={styles.label}
              />
              <FormHelperText className={styles.notification} error={isSubmitted && errors.checkbox}>
                {isSubmitted && errors.checkbox ? errors.checkbox?.message : " "}
              </FormHelperText>
            </>
          )}
        </LocalizationProvider>
        <button className={`${styles.button} global-button`} disabled={isLoading}>
          {type === "create" ? "Confirm" : "Save"}
        </button>
        {type !== "create" && (
          <button className={styles.remover} onClick={onAccountDelete}>
            <BucketIcon className={styles.icon} />
            Delete account
          </button>
        )}
      </form>
      {isSuccess && <SuccessMessage message={"Your request was successfully send"} className={styles.message} />}
      {error && <p className={styles.error}>{error}</p>}
    </>
  );
};

CompanyDetailsForm.propTypes = {
  formHandler: PropTypes.func.isRequired,
  onAccountDelete: PropTypes.func,
  defaultFormData: PropTypes.object,
  isInfoApproved: PropTypes.bool,
  isLoading: PropTypes.bool,
  error: PropTypes.string,
  type: PropTypes.string,
};

export default observer(CompanyDetailsForm);
