import { Link, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";

import { useStores } from "../../hooks/use-stores";
import FileReader from "../../components/FileReader/FileReader";
import styles from "./Goods.module.scss";

const Goods = () => {
  const iframeNode = useRef();
  const {
    catalog: { getCatalogItem, catalogItem },
    userDetails: { isPlanSelected, KYC, isSubscribeActive },
  } = useStores();
  const { id } = useParams();
  const linkState = {
    file: { id: catalogItem?.file?.id, url: catalogItem?.file?.url, name: catalogItem?.file?.file.originalName, goodsName: catalogItem?.name },
  };
  const fileLink = `https://docs.google.com/viewerng/viewer?url=${catalogItem?.file?.url}&embedded=true`;

  useEffect(() => getCatalogItem(id), []);

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>{catalogItem?.name}</h2>
      {catalogItem?.file?.file.mimeType.includes("spreadsheetml") ? (
        <div className={styles.description}>
          <FileReader data={catalogItem?.file} />
          <p className={styles.descriptionFirst}>File of this type is not available for preview.</p>
          <p>In order to view this file, you should download it by clicking on the link.</p>
        </div>
      ) : (
        <iframe className={styles.container} src={fileLink} ref={iframeNode}></iframe>
      )}
      {KYC ? (
        isPlanSelected ? (
          <Link
            className={`${styles.link} ${isSubscribeActive && KYC?.status === "confirmed" ? "" : styles.disabled} global-button`}
            to={{ pathname: "/create-order", state: linkState }}>
            Make order
          </Link>
        ) : (
          <Link className={`${styles.link} ${styles.width} global-button`} to="/first-login">
            Choose subscription
          </Link>
        )
      ) : (
        <Link className={`${styles.link} global-button`} to="/first-login">
          Update Profile
        </Link>
      )}
    </div>
  );
};

export default observer(Goods);
