export const validateFileType = (file) => {
  const allowedFileTypes =
    "pdf/xls/xlsx/png/jpeg/jpg/image/jpeg/application/pdf/image/png/application/vnd.ms-excel/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  const sizeInMB = (file.size / (1024 * 1024)).toFixed(2);
  if (!("type" in file) || !allowedFileTypes.includes(file.type) || file.type.length === 0) {
    alert(`${file.name} file type is not allowed`);
    return false;
  }
  if (sizeInMB > 10) {
    alert(`${file.name} file size exceed allowed maximum`);
    return false;
  }
  return file;
};
