import { Link } from "react-router-dom";
import { useState } from "react";
import { observer } from "mobx-react-lite";
import styles from "./VideoBackground.module.scss";

import SmartDrop from "./SmartDrop.mp4";
import ReactGA from "react-ga4";
import { ReactComponent as MuteIcon } from "../../assets/icons/mute.svg";
import { ReactComponent as SoundIcon } from "../../assets/icons/sound.svg";
import { ReactComponent as MouseIcon } from "../../assets/icons/mouse.svg";
/* eslint-disable */

const VideoBackground = () => {
  const [ videoSound, setVideoSound ] = useState(false);

  function createGE() {
    ReactGA.event({
      category: "Customer registration",
      action: "Customer registration",
    });
  };

  return (
    <div className={styles.videoWrapper}>
      { videoSound ? <SoundIcon className={styles.soundIcon} onClick={() => setVideoSound(false)} /> 
                  : <MuteIcon className={styles.soundIcon} onClick={() => setVideoSound(true)}  /> }
      { videoSound ? <video className={styles.videoTag} autoPlay loop>
                      <source src={SmartDrop} type="video/mp4" />
                    </video>
                  : <video className={styles.videoTag} autoPlay loop muted>
                        <source src={SmartDrop} type="video/mp4" />
                    </video> }
      <div className={styles.videoActions}>
        <button className="global-button" onClick={createGE}>7 days free
          <Link to={{ pathname: '/register?7daysFree'}} target="_blank" />
        </button>
        <MouseIcon className={styles.mouseIcon} /> 
      </div>
    </div>
  );
};

export default observer(VideoBackground);
