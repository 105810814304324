import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useRef, useEffect } from "react";
import PropTypes from "prop-types";

import { orderStatuses } from "../../helpers/contstants";
import styles from "./ScrolledStatus.module.scss";
const tabs = Object.keys(orderStatuses);
const sliderOptions = {
  type: "slide",
  arrows: false,
  autoWidth: false,
  rewind: false,
  pagination: false,
  // autoWidth: true,
};

const ScrolledStatus = ({ currentStatus }) => {
  const slider = useRef();

  useEffect(() => {
    const tabIndex = tabs.findIndex((tab) => tab === currentStatus);
    slider.current.go(tabIndex);
  }, []);

  return (
    <Splide className={styles.wrapper} options={sliderOptions} ref={slider}>
      {tabs.map((tab) => (
        <SplideSlide className={`${styles.tab} ${tab === currentStatus ? styles.active : ""}`} key={tab}>
          {orderStatuses[tab]}
        </SplideSlide>
      ))}
    </Splide>
  );
};

ScrolledStatus.propTypes = {
  currentStatus: PropTypes.string.isRequired,
};

export default ScrolledStatus;
