import TextareaAutosize from "@mui/material/TextareaAutosize";
import { yupResolver } from "@hookform/resolvers/yup";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { observer } from "mobx-react-lite";
import { useForm } from "react-hook-form";
import { useState, useRef } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";

import SuccessMessage from "../SuccessMessage/SuccessMessage";
import { orderCategories } from "../../helpers/contstants";
import FileUploader from "../FileUploader/FileUploader";
import ReactGA from "react-ga4";
import styles from "./FindProduct.module.scss";
/* eslint-disable */

const validationSchema = Yup.object().shape({
  comment: Yup.string().notRequired().max(500, "Comments must not exceed 500 characters"),
  name: Yup.string()
    .required("Product name required")
    .min(2, "Product name required must be at least 2 characters")
    .max(150, "Product name must not exceed 150 characters"),
  cargoSpecification: Yup.string()
    .required("Cargo specification is required")
    .min(2, "Cargo specification must be at least 2 characters")
    .max(150, "Cargo specification must not exceed 150 characters"),
  value: Yup.string()
    .required("Value is required")
    .min(1, "Value specification must be at least 1 characters")
    .max(150, "Value specification must not exceed 150 characters"),
  item: Yup.string()
    .required("Item is required")
    .min(1, "Item specification must be at least 1 characters")
    .max(150, "Item specification must not exceed 150 characters"),
  dimensions: Yup.string()
    .required("Dimensions is required")
    .min(1, "Dimensions must be at least 1 characters")
    .max(150, "Dimensions must not exceed 150 characters"),
  kindOfGoods: Yup.string()
    .required("Kind of goods is required")
    .min(2, "Kind of goods must be at least 2 characters")
    .max(150, "Kind of goods must not exceed 150 characters"),
  category: Yup.string().required("Category is required"),
});

const FindProduct = ({ formDataHandler, error, isLoading }) => {
  const [isSuccess, setSuccessStatus] = useState(false);
  const [category, setCategory] = useState("");
  const uploaderNode = useRef();
  const {
    formState: { errors, isSubmitted },
    handleSubmit,
    clearErrors,
    register,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const onSuccess = () => setSuccessStatus(true);

  const changeCategory = (event) => {
    clearErrors("category");
    setCategory(event.target.value);
  };

  function createGE() {
    ReactGA.event({
      category: "Creating an order",
      action: "Creating an order",
    });
  };

  return (
    <form className={styles.wrapper} onSubmit={handleSubmit((data) => formDataHandler(data, onSuccess, uploaderNode.current.getFilesInfo()))}>
      <TextField
        helperText={isSubmitted && errors.name ? errors.name.message : " "}
        color={`${isSubmitted ? "success" : "grey"}`}
        error={isSubmitted && !!errors.name}
        label="Order name*"
        {...register("name")}
        name="name"
        variant="filled"
      />
      <TextField
        helperText={isSubmitted && errors.cargoSpecification ? errors.cargoSpecification.message : " "}
        error={isSubmitted && !!errors.cargoSpecification}
        color={`${isSubmitted ? "success" : "grey"}`}
        {...register("cargoSpecification")}
        label="Cargo specification*"
        name="cargoSpecification"
        variant="filled"
      />
      <TextField
        helperText={isSubmitted && errors.category ? errors.category.message : " "}
        color={`${isSubmitted ? "success" : "grey"}`}
        error={isSubmitted && !!errors.category}
        id="outlined-select-category"
        placeholder="Select category"
        {...register("category")}
        onChange={changeCategory}
        label="Select category"
        sx={{ width: "100%" }}
        variant="filled"
        value={category}
        select>
        {orderCategories.map((option) => (
          <MenuItem sx={{ width: "100%" }} key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        helperText={isSubmitted && errors.dimensions ? errors.dimensions.message : " "}
        color={`${isSubmitted ? "success" : "grey"}`}
        error={isSubmitted && !!errors.dimensions}
        {...register("dimensions")}
        label="Dimensions*"
        name="dimensions"
        variant="filled"
      />
      <TextField
        helperText={isSubmitted && errors.item ? errors.item.message : " "}
        color={`${isSubmitted ? "success" : "grey"}`}
        error={isSubmitted && !!errors.item}
        {...register("item")}
        variant="filled"
        label="Item*"
        name="item"
      />
      <TextField
        helperText={isSubmitted && errors.value ? errors.value.message : " "}
        color={`${isSubmitted ? "success" : "grey"}`}
        error={isSubmitted && !!errors.value}
        {...register("value")}
        variant="filled"
        label="Value*"
        name="value"
      />
      <TextField
        helperText={isSubmitted && errors.kindOfGoods ? errors.kindOfGoods.message : " "}
        color={`${isSubmitted ? "success" : "grey"}`}
        error={isSubmitted && !!errors.kindOfGoods}
        {...register("kindOfGoods")}
        label="Kind of goods*"
        name="kindOfGoods"
        variant="filled"
      />
      <TextareaAutosize
        placeholder="Max length: 500 symbol"
        className="global-textarea"
        aria-label="maximum height"
        {...register("comment")}
        maxLength={500}
        minRows={4}
        maxRows={4}
      />
      <FileUploader inputID="find-product-file-uploader" ref={uploaderNode}/>
      <button className="global-button" disabled={isLoading} onClick={createGE}>Create order</button>
      {isSuccess && <SuccessMessage message={"Your request was successfully send"} className={styles.notification}/>}
      {error && <p className="global-error">{error}</p>}
    </form>
  );
};

FindProduct.propTypes = {
  formDataHandler: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  error: PropTypes.string,
};

export default observer(FindProduct);
