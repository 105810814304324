import { observable, action, computed, makeObservable } from "mobx";
import { defaultOrdersPerPageAmount } from "../helpers/contstants";
import orderEndpoints from "../api/endpoints/order";
import userDetails from "./user-details";
import { axios } from "../utils/axios";

class Order {
  ordersPerPageAmount = defaultOrdersPerPageAmount;
  currentOrder = null;
  isLoading = false;
  orders = null;
  error = null;

  async setOrder(payload) {
    this.setLoading(true);
    this.setError(null);
    const method = orderEndpoints.create.method;
    const path = orderEndpoints.create.path;
    try {
      const response = await axios[method](path, payload);
      return response;
    } catch (error) {
      console.error(error);
      this.setError("Something went wrong! Please contact support or try again later.");
    } finally {
      this.setLoading(false);
    }
  }

  async updateOrder(orderID, payload) {
    delete payload.id;
    this.setLoading(true);
    this.setError(null);
    const method = orderEndpoints.update.method;
    const path = orderEndpoints.update.path.replace(":id", orderID);
    try {
      const response = await axios[method](path, payload);
      return response;
    } catch (error) {
      console.error(error);
      this.setError("Something went wrong! Please contact support or try again later.");
    } finally {
      this.setLoading(false);
    }
  }

  async getOrders(status = null, offset = 0, sortBy, direction) {
    this.setLoading(true);
    this.setError(null);
    const method = orderEndpoints.list.method;
    const path = orderEndpoints.list.path;
    try {
      const response = await axios[method](path, {
        params: {
          "filter[user]": `${userDetails.identity.id}`,
          limit: this.ordersPerPageAmount,
          "filter[status]": status,
          sortDirection: direction,
          sortField: sortBy,
          offset,
        },
      });
      response && this.setOrders(response);
    } catch (error) {
      console.error(error);
      this.setError("Something went wrong! Please contact support or try again later.");
    } finally {
      this.setLoading(false);
    }
  }

  async getCurrentOrder(id) {
    this.setLoading(true);
    this.setError(null);
    const method = orderEndpoints.get.method;
    const path = orderEndpoints.get.path.replace(":id", id);
    try {
      const response = await axios[method](path);
      response && this.setCurrentOrder(response);
    } catch (error) {
      console.dir(error);
      error.response.data.error === "resource_not_found"
        ? this.setError("Order was deleted or not found")
        : this.setError("Something went wrong! Please contact support or try again later.");
    } finally {
      this.setLoading(false);
    }
  }

  async cancelOrder(id) {
    this.setLoading(true);
    this.setError(null);
    const method = orderEndpoints.cancel.method;
    const path = orderEndpoints.cancel.path.replace(":id", id);
    try {
      const response = await axios[method](path, { status: "canceled" });
      response && this.setCurrentOrder(null);
    } catch (error) {
      console.error(error);
      this.setError("Something went wrong! Please contact support or try again later.");
    } finally {
      this.setLoading(false);
    }
  }

  setOrders(orders) {
    this.orders = orders;
  }

  setCurrentOrder(order) {
    this.currentOrder = order;
  }

  updateOrderStatus(status) {
    if (this.currentOrder && this.currentOrder.status) {
      this.currentOrder.status = status;
    }
  }

  setLoading(status) {
    this.isLoading = status;
  }

  setError(error) {
    this.error = error;
  }

  setOrderOnPageAmount(integer) {
    this.ordersPerPageAmount = integer;
  }

  get orderStatus() {
    return this.currentOrder?.status;
  }
}

const order = new Order();

makeObservable(order, {
  orderStatus: computed,
  updateOrderStatus: action.bound,
  setOrderOnPageAmount: action.bound,
  ordersPerPageAmount: observable,
  setCurrentOrder: action.bound,
  getCurrentOrder: action.bound,
  updateOrder: action.bound,
  cancelOrder: action.bound,
  currentOrder: observable,
  setLoading: action.bound,
  setOrders: action.bound,
  getOrders: action.bound,
  setOrder: action.bound,
  setError: action.bound,
  isLoading: observable,
  orders: observable,
  error: observable,
});

export default order;
