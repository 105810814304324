import { Link, useHistory } from "react-router-dom";
import { useEffect, useRef } from "react";
import PropTypes from "prop-types";

import { ReactComponent as SuccessPaymentImage } from "../../assets/images/success-payment.svg";
import AccessDenied from "../../components/AccessDenied/AccessDenied";
import { useStores } from "../../hooks/use-stores";
import styles from "./SuccessPayment.module.scss";

const SuccessPayment = ({ location: { state } }) => {
  const isSuccess = useRef();
  const {
    userDetails: { getUserDetails },
  } = useStores();
  const history = useHistory();
  useEffect(() => {
    if (state?.isSuccess) {
      isSuccess.current = state.isSuccess;
      history.replace();
    }
    return () => getUserDetails();
  }, []);

  return (
    <div className={styles.wrapper}>
      {isSuccess.current ? (
        <>
          <h2>Payment and details</h2>
          <h3 className={styles.notification}>Payment has been processed successfully!</h3>
          <SuccessPaymentImage className={styles.image} />
          <Link className="global-button" to={"/dashboard"}>
            my dashboard
          </Link>
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
};

SuccessPayment.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      isSuccess: PropTypes.bool,
    }),
  }).isRequired,
};

export default SuccessPayment;
