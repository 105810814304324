import styles from "./SetPassword.module.scss";
import Form from "../../components/Form/Form";
import { useStores } from "../../hooks/use-stores";
import { Link } from "react-router-dom";
import { useState } from "react";

const SetPassword = () => {
  const [success, setSuccess] = useState(false);
  const {
    authentication: { resetPassword },
  } = useStores();

  const onSetPassword = async (data) => {
    const path = window.location.pathname.split("/");
    const token = path[2];
    const { password } = data;
    try {
      await resetPassword({
        token,
        password,
      });
      setSuccess(true);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      {success ? (
        <p className={styles.notice}>
            Password was changed.
          <br />
            Now you can <Link className={"global-link"} to="/login">log in</Link> with new password
        </p>
      ) : (
        <Form type="set password" dataHandler={onSetPassword} />
      )}
    </>
  );
};

export default SetPassword;
