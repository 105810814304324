import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";

import { countries } from "../../helpers/contstants";
import styles from "./ConsigneeDetails.module.scss";

const ConsigneeDetails = ({ isSubmitted, errors, register }) => (
  <>
    <h3>Consignee details</h3>
    <div className={styles.wrapper}>
      <TextField
        helperText={isSubmitted && errors.consigneeName ? errors.consigneeName.message : " "}
        color={`${isSubmitted ? "success" : "grey"}`}
        error={isSubmitted && !!errors.consigneeName}
        {...register("consigneeName")}
        label="Consignee name*"
        name="consigneeName"
        variant="filled"
      />
      <TextField
        helperText={isSubmitted && errors.consigneePostcode ? errors.consigneePostcode.message : " "}
        error={isSubmitted && !!errors.consigneePostcode}
        color={`${isSubmitted ? "success" : "grey"}`}
        {...register("consigneePostcode")}
        name="consigneePostcode"
        label="Postcode*"
        variant="filled"
      />
      <TextField
        helperText={isSubmitted && errors.consigneeContactPerson ? errors.consigneeContactPerson.message : " "}
        color={`${isSubmitted ? "success" : "grey"}`}
        error={isSubmitted && !!errors.consigneeContactPerson}
        {...register("consigneeContactPerson")}
        name="consigneeContactPerson"
        label="Contact person*"
        variant="filled"
      />
      <TextField
        helperText={isSubmitted && errors.consigneeCity ? errors.consigneeCity.message : " "}
        color={`${isSubmitted ? "success" : "grey"}`}
        error={isSubmitted && !!errors.consigneeCity}
        {...register("consigneeCity")}
        name="consigneeCity"
        variant="filled"
        label="City*"
      />
      <TextField
        helperText={isSubmitted && errors.consigneePhone ? errors.consigneePhone.message : " "}
        color={`${isSubmitted ? "success" : "grey"}`}
        error={isSubmitted && !!errors.consigneePhone}
        {...register("consigneePhone")}
        name="consigneePhone"
        variant="filled"
        label="Phone*"
      />
      <TextField
        helperText={isSubmitted && errors.consigneeState ? errors.consigneeState.message : " "}
        color={`${isSubmitted ? "success" : "grey"}`}
        error={isSubmitted && !!errors.consigneeState}
        {...register("consigneeState")}
        name="consigneeState"
        variant="filled"
        label="State*"
      />
      <TextField
        helperText={isSubmitted && errors.consigneeEmail ? errors.consigneeEmail.message : " "}
        color={`${isSubmitted ? "success" : "grey"}`}
        error={isSubmitted && !!errors.consigneeEmail}
        {...register("consigneeEmail")}
        name="consigneeEmail"
        variant="filled"
        label="Email*"
      />
      <Autocomplete
        id="country-select-demo"
        options={countries}
        autoHighlight
        getOptionLabel={(option) => option.label}
        renderOption={(props, option) => (
          <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
            <img
              loading="lazy"
              width="20"
              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
              alt=""
            />
            {option.label} ({option.code})
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            helperText={isSubmitted && errors.consigneeCountry ? errors.consigneeCountry.message : " "}
            error={isSubmitted && !!errors.consigneeCountry}
            color={`${isSubmitted ? "success" : "grey"}`}
            {...register("consigneeCountry")}
            className={styles.autocomplete}
            label="Country*"
            variant="filled"
            {...params}
          />
        )}
      />
      <TextField
        helperText={isSubmitted && errors.consigneeContactDestination ? errors.consigneeContactDestination.message : " "}
        error={isSubmitted && !!errors.consigneeContactDestination}
        color={`${isSubmitted ? "success" : "grey"}`}
        {...register("consigneeContactDestination")}
        name="consigneeContactDestination"
        label="Contact person at destination*"
        variant="filled"
      />
    </div>
  </>
);

ConsigneeDetails.propTypes = {
  isSubmitted: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
};

export default ConsigneeDetails;
