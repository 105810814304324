import styles from "./Burger.module.scss";
import PropTypes from "prop-types";

const Burger = ({ onBurgerClick, isMenuOpen }) => <div className={`${styles.button} ${isMenuOpen ? styles.cross : ""}`} onClick={onBurgerClick}></div>;

export default Burger;

Burger.propTypes = {
  onBurgerClick: PropTypes.func,
  isMenuOpen: PropTypes.bool,
};
