import { Link, useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";
import PropTypes from "prop-types";

import { useStores } from "../../hooks/use-stores";
import Form from "../../components/Form/Form";
import styles from "./Login.module.scss";

const Login = ({ location: { state } }) => {
  const emailConfirmationMessage = useRef();
  const {
    authentication: { login, error, clearError, isLoading },
    userDetails: { identityError },
  } = useStores();
  const history = useHistory();

  const sendUserDataToApi = (userData) => {
    login({
      password: userData.password,
      username: userData.email,
    });
  };

  useEffect(() => {
    if (state?.emailConfirmationMessage) emailConfirmationMessage.current = state.emailConfirmationMessage;
    history.replace();
    return () => clearError();
  }, []);

  return (
    <>
      <Form
        className={styles.form}
        type="login"
        dataHandler={sendUserDataToApi}
        apiErrorMessage={error}
        loading={isLoading}
        link={
          <Link className={`${styles.link} global-link`} to="/forgot-password">
            Forgot password
          </Link>
        }
      />
      {identityError && <p className={`${styles.notification} global-error`}>{identityError}</p>}
      {emailConfirmationMessage.current && <p className={styles.notification}>{emailConfirmationMessage.current}</p>}
    </>
  );
};

Login.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      emailConfirmationMessage: PropTypes.string,
    }),
  }).isRequired,
};

export default observer(Login);
