import { observer } from "mobx-react-lite";
import PropTypes from "prop-types";

import { useStores } from "../../hooks/use-stores";
import { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import styles from "./Layout.module.scss";
import Header from "../Header/Header";
import VideoBackground from "../VideoBackground/VideoBackground";
import Landing from "../Landing/Landing";
import styled from "@emotion/styled";

/* eslint-disable */

const Layout = ({ children }) => {
  const {
    userDetails: { isSubscribeActive, isPlanSelected, KYC },
  } = useStores();
  const [currentPath, setCurrentPath] = useState("");
  const loc = useLocation();
  const query = useHistory();

  useEffect(() => {
    setCurrentPath(loc.pathname);
  }, [loc]);

  const isNotificationShowed = (isSubscribeActive !== null && !isSubscribeActive && isPlanSelected) || KYC?.status === "inreview";
  const isInActive = isSubscribeActive !== null && !isSubscribeActive && isPlanSelected;

  return (
    <>
      <Header />
      {currentPath == "/" && !query.location.search ? <VideoBackground /> : <></>}
      <main className={`${currentPath !== "/" ? styles.receptacle : styles.receptacleRegister} ${isNotificationShowed ? styles.padding : ""} global-wrapper`}>
        {isNotificationShowed && <p className={`${styles.notification} ${isInActive ? "" : styles.orange}`}>{isInActive ? "Your subscription inactive, please make payment" : "Your company details are being verified"}</p>}
        {children}
      </main>
      {currentPath == "/"  && !query.location.search ? <Landing /> : <></>}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default observer(Layout);
