import PropTypes from "prop-types";

import { ReactComponent as TickIcon } from "../../assets/icons/tick.svg";
import styles from "./SuccessMessage.module.scss";

const SuccessMessage = ({ message, className }) => {
  return (
    <p className={`${styles.wrapper} ${className}`}>
      <span className={styles.tick}>
        <TickIcon />
      </span>
      { message }
    </p>
  );
};

SuccessMessage.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
};

export default SuccessMessage;
