import { Link } from "react-router-dom";
import PropsType from "prop-types";
import { useState } from "react";

import { ReactComponent as AdvancedIcon } from "../../assets/icons/advanced.svg";
import { ReactComponent as TickIcon } from "../../assets/icons/tick.svg";
import { ReactComponent as BaseIcon } from "../../assets/icons/base.svg";
import { ReactComponent as ProIcon } from "../../assets/icons/pro.svg";
import ReactGA from "react-ga4";
import styles from "./SubscribePlan.module.scss";

const planDiscription = [
  {
    storage: "£5,00",
    picking: "£0,50",
    label: "£0,36",
    pallet: "£5.00 per pallet",
    wrap: "£15.00 per pallet",
    costs: "NET + 20% handling fee",
    management: "£30,00 per man hour",
    icon: <BaseIcon />,
  },
  {
    storage: "£4,50",
    picking: "£0,40",
    label: "£0,25",
    pallet: "£4.00 per pallet",
    wrap: "£12.00 per pallet",
    costs: "NET + 15% handling fee",
    management: "£28,00 per man hour",
    icon: <ProIcon />,
  },
  {
    storage: "½ month free +£4,00",
    picking: "£0,28",
    label: "FREE",
    pallet: "FREE",
    wrap: "£10.00 per pallet",
    costs: "NET + 10% handling fee",
    management: "£25,00 per man hour",
    icon: <AdvancedIcon />,
  },
];

const SubscribePlan = ({ data, loading, subscribeHandler, error, type, isAuthenticated }) => {
  const [selectedId, setSelectedId] = useState(null);

  const confirmSubscribe = () => {
    subscribeHandler(data.find((plan) => plan.id === selectedId));
    ReactGA.event({
      category: "Offer activation",
      action: "Offer activation",
    });
  };
  const onUpdateId = (id) => () => setSelectedId(id);

  return (
    <>
      <ul className={styles.wrapper}>
        {data.map((item, index) => {
          return (
            item.id && (
              <SubscribeCard
                isAuthenticated={isAuthenticated}
                isSelected={selectedId === item.id}
                storage={planDiscription[index].storage}
                picking={planDiscription[index].picking}
                label={planDiscription[index].label}
                pallet={planDiscription[index].pallet}
                wrap={planDiscription[index].wrap}
                costs={planDiscription[index].costs}
                management={planDiscription[index].management}
                price={item.price.amount / 100}
                logo={planDiscription[index].icon}
                getId={onUpdateId}
                title={item.name}
                key={item.id}
                id={item.id}
                type={type}
              />
            )
          );
        })}
      </ul>
      <div className={styles.wrapper}>
        {type === "subscribe" && (
          <button className={`${styles.button} global-button`} disabled={loading || !selectedId} onClick={confirmSubscribe}>
            Confirm
          </button>
        )}
        {error && <p className={`${styles.error} global-error`}>{error}</p>}
      </div>
    </>
  );
};

SubscribePlan.propTypes = {
  isAuthenticated: PropsType.bool,
  subscribeHandler: PropsType.func,
  data: PropsType.array.isRequired,
  loading: PropsType.bool,
  error: PropsType.string,
  type: PropsType.string,
};

export default SubscribePlan;

const SubscribeCard = ({ title, logo, price, storage, picking, label, pallet, wrap, costs, management, id, getId, isSelected, type, isAuthenticated }) => {
  const [isShovedMore, setShowedMoreStatus] = useState(false);
  const toggleDescriptionVisibility = () => setShowedMoreStatus((previousStatus) => !previousStatus);

  return (
    <li
      className={`${styles.item} ${isSelected ? styles.selected : ""} ${type === "pricing" ? styles.inactive : ""}`}
      onClick={type === "subscribe" ? getId(id) : undefined}>
      <div className={styles.headline}>
        {logo}
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.price}>
          <span className={styles.symbol}>&pound;</span>
          <span className={styles.integer}>{price}</span> per 1 month
        </p>
      </div>
      <div className={styles.message}>Give Us a Try with our 7-Days Free</div>

      <ul className={styles.list}>
        <li className={styles.info}>
          <TickIcon className={styles.tick} />
          24/7 support (incl. shipping support)
        </li>
        <li className={styles.info}>
          <TickIcon className={styles.tick} />
          Provide VAT Registration
        </li>
        <li className={styles.info}>
          <TickIcon className={styles.tick} />
          Individual selection of goods
        </li>
        <li className={styles.info}>
          <TickIcon className={styles.tick} />
          Access to the product catalog (incl. Exclusive partners)
        </li>
      </ul>

      <p className={styles.toggler} onClick={toggleDescriptionVisibility}>Show more<span className={`${styles.arrow} ${isShovedMore ? styles.reversed : ""}`}/></p>

      <ul className={`${styles.list} ${styles.description} ${isShovedMore ? styles.open : ""}`}>
        <li className={styles.condition}>
          <span className={styles.heading}>Storage per pallet or part thereof per week</span>
          {storage}
        </li>
        <li className={styles.condition}>
          <span className={styles.heading}>Picking fee</span>
          {picking}
        </li>
        <li className={styles.condition}>
          <span className={styles.heading}>Label creation</span>
          {label}
        </li>
        <li className={styles.condition}>
          <span className={styles.heading}>RH&D Pallet in or out</span>
          {pallet}
        </li>
        <li className={styles.condition}>
          <span className={styles.heading}>Pallet & wrap fee</span>
          {wrap}
        </li>
        <li className={styles.condition}>
          <span className={styles.heading}>Packaging costs</span>
          {costs}
        </li>
        <li className={styles.condition}>
          <span className={styles.heading}>Inventory management</span>
          {management}
        </li>
      </ul>
      {type === "pricing" && (
        <Link className={`${styles.link} global-button`} to={isAuthenticated ? "/user-profile" : "/register"}>
          choose
        </Link>
      )}
    </li>
  );
};

SubscribeCard.propTypes = {
  storage: PropsType.string.isRequired,
  pallet: PropsType.string.isRequired,
  picking: PropsType.string.isRequired,
  label: PropsType.string.isRequired,
  isAuthenticated: PropsType.bool,
  getId: PropsType.func.isRequired,
  title: PropsType.string.isRequired,
  logo: PropsType.element.isRequired,
  price: PropsType.number.isRequired,
  type: PropsType.string.isRequired,
  wrap: PropsType.string.isRequired,
  costs: PropsType.string.isRequired,
  management: PropsType.string.isRequired,
  id: PropsType.string.isRequired,
  isSelected: PropsType.any,
};
