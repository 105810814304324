import { orderStatuses } from "../../../helpers/contstants";
import { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";

const DashboardMobileItem = ({ order }) => {
  const itemNodeHeight = useRef();
  const itemNode = useRef();
  const [isOpen, setOpen] = useState(false);
  const onToggle = () => {
    if (isOpen) {
      itemNode.current.style.height = `${itemNode.current.children[0].offsetHeight}px`;
    } else {
      itemNode.current.style.height = itemNodeHeight.current;
    }
    setOpen(!isOpen);
  };

  useEffect(() => {
    if (itemNode.current) {
      itemNodeHeight.current = `${itemNode.current.offsetHeight}px`;
      itemNode.current.style.height = `${itemNode.current.children[0].offsetHeight}px`;
    }
  }, [itemNode]);

  return (
    <div className={"order-accordion__item " + (isOpen ? "active" : "")} ref={itemNode}>
      <div className="order-accordion__header" onClick={onToggle}>
        <small>Order name</small> <br />
        <b>{order.name}</b>
        <a href="#" className="order-accordion__button"></a>
      </div>
      <Link to={`/order/${order.id}`} className="order-accordion__content">
        <div className="order-accordion__inner">
          <div className="order-accordion__line">
            <div>ID</div>
            <div>
              <b>{order.id}</b>
            </div>
          </div>
          <div className="order-accordion__line">
            <div>Status</div>
            <div className="order-accordion__status order-accordion__status--progress">{orderStatuses[order.status]}</div>
          </div>
          <div className="order-accordion__line">
            <div>Date</div>
            <div>
              <b>{moment(order.createdAt).format("MMM Do YY")}</b>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

DashboardMobileItem.propTypes = {
  order: PropTypes.object,
};

export default DashboardMobileItem;
