import { useState, forwardRef, useImperativeHandle, useRef } from "react";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import { useEffect } from "react";

import { ReactComponent as CrossIcon } from "../../assets/icons/cross-icon.svg";
import { ReactComponent as ClipIcon } from "../../assets/icons/clip.svg";
import { validateFileType } from "../../helpers/functions";
import { useStores } from "../../hooks/use-stores";
import styles from "./FileUploader.module.scss";

// eslint-disable-next-line react/display-name
const FileUploader = forwardRef(({ inputID, isMultiple, className, withoutName, withCounter, catalogFile }, ref) => {
  const [filesInfo, setFilesInfo] = useState([]);
  const inputNode = useRef();
  const {
    file: { sendFile, deleteFile, error, loading },
  } = useStores();

  useEffect(() => catalogFile && setFilesInfo([catalogFile]), [catalogFile]);

  useImperativeHandle(ref, () => ({
    clearFilesInfo: () => {
      setFilesInfo([]);
      inputNode.current.value = null;
    },
    getFilesInfo: () => filesInfo,
  }));

  const removeFile = (id) => async () => {
    if (!loading) {
      await deleteFile(id);
      const newFilesInfo = filesInfo.filter((file) => file.id !== id);
      setFilesInfo(newFilesInfo);
      inputNode.current.value = null;
    }
  };

  const onFileAdd = async (event) => {
    const validFiles = Array.from(event.target.files).reduce((previousValue, currentValue) => {
      const validFile = validateFileType(currentValue);
      if (validFile) {
        return [...previousValue, validFile];
      } else {
        return previousValue;
      }
    }, []);

    const newFilesInfo = await validFiles.reduce(async (previousPromise, currentValue) => {
      const previousValue = await previousPromise;
      const form = new FormData();
      form.append("file", currentValue);
      const { id } = await sendFile(form);
      if (id) {
        return [...previousValue, { name: currentValue.name, id }];
      } else return previousValue;
    }, Promise.resolve([]));

    setFilesInfo(newFilesInfo);
  };

  return (
    <div className={`${styles.wrapper} ${className}`}>
      <input
        accept="image/png, image/jpeg, application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        className={styles.uploader}
        multiple={isMultiple}
        onChange={onFileAdd}
        ref={inputNode}
        id={inputID}
        type="file"
      />
      <label htmlFor={inputID} className={styles.attach}>
        <ClipIcon />
        {!withoutName && "Attach file"}
        {withCounter && filesInfo.length > 0 && <span className={styles.counter}>{filesInfo.length}</span>}
      </label>
      {!withoutName && (
        <div className={styles.info}>
          {filesInfo.length > 0 &&
            filesInfo.map((file, index) => (
              <p className={styles.text} key={index}>
                {file.name}
                <span className={styles.cross} onClick={removeFile(file.id)}>
                  <CrossIcon />
                </span>
              </p>
            ))}
        </div>
      )}
      {error && <p className={`${styles.error} global-error`}>{error}</p>}
    </div>
  );
});

FileUploader.propTypes = {
  inputID: PropTypes.string.isRequired,
  className: PropTypes.string,
  withoutName: PropTypes.bool,
  withCounter: PropTypes.bool,
  isMultiple: PropTypes.bool,
  catalogFile: PropTypes.shape({
    goodsName: PropTypes.string,
    name: PropTypes.string,
    url: PropTypes.string,
    id: PropTypes.string,
  }),
  ref: PropTypes.object,
};

export default observer(FileUploader);
