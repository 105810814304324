import { observer } from "mobx-react-lite";
import PropTypes from "prop-types";
import styles from "./Landing.module.scss";

import AboutUs from "../../views/AboutUs/AboutUs";
import Footer from "../Footer/Footer";
import { style } from "@mui/system";
import { useState } from "react";
/* eslint-disable */

const Landing = ({ children }) => {

  const [questions, setQuestions] = useState([
    {
      q: "What are your advantages?",
      a: "Smart Drop is the fast-growing dropshipping platform that gives a lot of benefits to our users. We have a significant experience in logistics solutions, and we have the best prices and conditions for parcel shipments. We have an individual approach for each user. Our managers will be happy to help you with any of your inquiries. ",
      isHidden : true
    },
    {
      q: "Why choose Smart Drop instead of other dropshipping suppliers? ",
      a: 'Smart Drop provides your all-in services as promised: sourcing, stocking, quality check product, a custom product, custom package inserts and shipping services.  Smart Drop is a disruptive product, including powerful tools: <br /> • Direct cooperation with more than 1000 manufacturers <br /> • Own shipping company, including +500 agents worldwide  <br />  • Fulfilment warehouse ',
      isHidden : true
    },
    {
      q: "How are you different from AliExpress?",
      a: "Smart Drop is the most customer focus dropshipping platform in the World. Our team is ready to assist you in choosing the most suitable commodity for your business. You will never face such challenges as you will have with AliExpress. Smart Drop Team secure your order to avoid a lack of control. Smart Drop provides a tool for order management for both clients and company staff to ensure efficient tracking of order status and communication between client and company.",
      isHidden : true
    }, 
  ]);

  function openItem(i, value) {
    let arr = questions;
    arr[i].isHidden = value;
    setQuestions([...arr]);
  };  

  return (
    <>
     <AboutUs/>
     <main className={`main ${styles.mainQuestions}`}>
      <div className="container">
        <section className={styles.questions}>
          <h1>Top questions from new friends</h1>
          <ul>
            {questions.map((q, i) => (
              <li className={styles.questionItem} key={i}>
                <div className={styles.questionHeader}>
                  <div className={styles.questionBody}>
                    <div className={styles.questionMarker}>Q</div>
                    <div className={styles.question}>{q.q}</div>
                  </div>
                  <div className={styles.questionAction}>
                    { !q.isHidden ? <div className={styles.triangleTop} onClick={() => openItem(i, true)}></div>
                    : <div className={styles.triangleBottom} onClick={() => openItem(i, false)}></div> }
                  </div>
                </div>
                <div className={!q.isHidden ? styles.questionAnswer : styles.hideAnswer}>
                  <div className={styles.answerMarker}>A</div>
                  <div className={styles.question} dangerouslySetInnerHTML={{ __html: q.a }}></div>
                </div>
              </li>
            ))}
          </ul>
        </section>
      </div>
      </main>
     {/* <Footer /> */}
    </>
  );
};

Landing.propTypes = {
  children: PropTypes.element.isRequired,
};

export default observer(Landing);
