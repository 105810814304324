import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { useStores } from "../../hooks/use-stores";
import styles from "./BreadCrumbs.module.scss";
const sliderOptions = {
  gap: "8px",
  type: "slide",
  arrows: false,
  autoWidth: true,
  rewind: false,
  pagination: false,
};

const BreadCrumbs = () => {
  const slider = useRef();
  const [parentCategories, setParentCategories] = useState([]);
  const {
    catalog: { catalogItem },
  } = useStores();
  const history = useHistory();

  useEffect(() => catalogItem && getParentCategories(catalogItem), [catalogItem]);
  useEffect(() => slider.current.go(4), [parentCategories]);

  const getParentCategories = (category) => {
    const parrentInfo = [];
    const checkParrent = (item) => {
      if (item.parent && item.parent.id !== 1) {
        parrentInfo.unshift({ name: item.parent.name, id: item.parent.id });
        checkParrent(item.parent);
      }
    };
    checkParrent(category);
    setParentCategories([{ name: "All", id: 1 }, ...parrentInfo, ...(category.id !== 1 ? [{ name: category.name, id: category.id }] : [])]);
  };

  const changeCategory = (id) => () => id === 1 ? history.push({ search: "" }) : history.push({ search: `category=${id}` });

  return (
    <Splide className={styles.wrapper} options={sliderOptions} ref={slider}>
      {parentCategories.map((parentCategory) => (
        <SplideSlide className={styles.item} key={parentCategory.id}>
          {parentCategory.id !== 1 && "> "}
          <span className={styles.heading} onClick={changeCategory(parentCategory.id)}>
            {parentCategory.name}
          </span>
          &nbsp;
        </SplideSlide>
      ))}
    </Splide>
  );
};

export default observer(BreadCrumbs);
