export default {
  register: {
    path: "/user-management/user",
    method: "post",
  },
  confirmEmail: {
    path: "/user-management/common/email-confirmation/:emailConfirmationToken",
    method: "get",
  },
  changeEmail: {
    path: "/user-management/common/email-changed/:emailConfirmationToken",
    method: "get",
  },
  resetPasswordConfirmation: {
    path: "/user-management/common/reset-password-confirmation",
    method: "post",
  },
  resetPassword: {
    path: "/user-management/common/reset-password",
    method: "post",
  },
  contactUs: {
    path: "/user-management/common/contact-us",
    method: "post",
  },
};
