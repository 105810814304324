import PropTypes from "prop-types";

import { orderCategories } from "../../helpers/contstants";
import Info from "../Info/Info";

const ProductInfo = ({ order }) => {
  return (
    <Info
      info={{
        ...(order.category && { Category: orderCategories.find((category) => category.value === order.category).label }),
        ...(order.cargoSpecification && { "Cargo specification": order.cargoSpecification }),
        ...(order.kindOfGoods && { "Kind of goods": order.kindOfGoods }),
        ...(order.dimensions && { Dimensions: order.dimensions }),
        ...(order.comment && { Comment: order.comment }),
        ...(order.name && { "Order name": order.name }),
        ...(order.value && { Value: order.value }),
        ...(order.item && { Item: order.item }),
      }}
    />
  );
};

ProductInfo.propTypes = {
  order: PropTypes.object.isRequired,
};

export default ProductInfo;
