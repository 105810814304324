import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import { ReactComponent as DashboardIcon } from "../../assets/icons/dashboard.svg";
import { ReactComponent as CatalogIcon } from "../../assets/icons/catalog.svg";
import { ReactComponent as PlusIcon } from "../../assets/icons/plus.svg";
import { useStores } from "../../hooks/use-stores";
import styles from "./ControlButtons.module.scss";

const ControlButton = ({ pageName, redirectPage, isReverse }) => {
  const {
    userDetails: { KYC, isSubscribeActive, isPlanSelected },
  } = useStores();

  return (
    <>
      <h2 className={styles.title}>My {pageName}</h2>
      <div className={`${styles.wrapper} ${isReverse ? styles.reverse : ""}`}>
        {KYC ? (
          isPlanSelected ? (
            <Link className={`${styles.link} ${isSubscribeActive && KYC?.status === "confirmed" ? "" : styles.disabled} global-button`} to="/create-order">
              <PlusIcon />
              New order
            </Link>
          ) : (
            <Link className={`${styles.link} ${styles.width} global-button`} to="/first-login">
              Choose subscription
            </Link>
          )
        ) : (
          <Link className={`${styles.link} global-button`} to="/first-login">
            Update Profile
          </Link>
        )}
        <Link className={`${styles.link} global-button white`} to={`/${redirectPage}`}>
          {redirectPage === "dashboard" && <DashboardIcon className={styles.icon} />}
          {redirectPage === "catalogue" && <CatalogIcon />}
          {redirectPage}
        </Link>
      </div>
    </>
  );
};

ControlButton.propTypes = {
  pageName: PropTypes.string.isRequired,
  redirectPage: PropTypes.string.isRequired,
  isReverse: PropTypes.bool,
};

export default ControlButton;
