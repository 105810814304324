import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";

import { countries } from "../../helpers/contstants";
import styles from "./ShipperDetails.module.scss";

const ShipperDetails = ({ isSubmitted, errors, register }) => (
  <>
    <h3>Shipper details</h3>
    <div className={styles.wrapper}>
      <TextField
        helperText={isSubmitted && errors.shipperName ? errors.shipperName.message : " "}
        color={`${isSubmitted ? "success" : "grey"}`}
        error={isSubmitted && !!errors.shipperName}
        {...register("shipperName")}
        label="Shipper name*"
        name="shipperName"
        variant="filled"
      />
      <TextField
        helperText={isSubmitted && errors.shipperPostcode ? errors.shipperPostcode.message : " "}
        error={isSubmitted && !!errors.shipperPostcode}
        color={`${isSubmitted ? "success" : "grey"}`}
        {...register("shipperPostcode")}
        name="shipperPostcode"
        label="Postcode*"
        variant="filled"
      />
      <TextField
        helperText={isSubmitted && errors.shipperContactPerson ? errors.shipperContactPerson.message : " "}
        error={isSubmitted && !!errors.shipperContactPerson}
        color={`${isSubmitted ? "success" : "grey"}`}
        {...register("shipperContactPerson")}
        name="shipperContactPerson"
        label="Contact person*"
        variant="filled"
      />
      <TextField
        helperText={isSubmitted && errors.shipperCity ? errors.shipperCity.message : " "}
        color={`${isSubmitted ? "success" : "grey"}`}
        error={isSubmitted && !!errors.shipperCity}
        {...register("shipperCity")}
        name="shipperCity"
        variant="filled"
        label="City*"
      />
      <TextField
        helperText={isSubmitted && errors.shipperPhone ? errors.shipperPhone.message : " "}
        color={`${isSubmitted ? "success" : "grey"}`}
        error={isSubmitted && !!errors.shipperPhone}
        {...register("shipperPhone")}
        name="shipperPhone"
        variant="filled"
        label="Phone*"
      />
      <TextField
        helperText={isSubmitted && errors.shipperState ? errors.shipperState.message : " "}
        color={`${isSubmitted ? "success" : "grey"}`}
        error={isSubmitted && !!errors.shipperState}
        {...register("shipperState")}
        name="shipperState"
        variant="filled"
        label="State*"
      />
      <TextField
        helperText={isSubmitted && errors.shipperEmail ? errors.shipperEmail.message : " "}
        color={`${isSubmitted ? "success" : "grey"}`}
        error={isSubmitted && !!errors.shipperEmail}
        {...register("shipperEmail")}
        name="shipperEmail"
        variant="filled"
        label="Email*"
      />
      <Autocomplete
        getOptionLabel={(option) => option.label}
        id="country-select-demo"
        options={countries}
        autoHighlight
        renderOption={(props, option) => (
          <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
            <img
              loading="lazy"
              width="20"
              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
              alt=""
            />
            {option.label} ({option.code})
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            className={styles.autocomplete}
            helperText={isSubmitted && errors.shipperCountry ? errors.shipperCountry.message : " "}
            error={isSubmitted && !!errors.shipperCountry}
            color={`${isSubmitted ? "success" : "grey"}`}
            {...register("shipperCountry")}
            variant="filled"
            label="Country*"
            {...params}
          />
        )}
      />
      <TextField
        helperText={isSubmitted && errors.shipperContactDestination ? errors.shipperContactDestination.message : " "}
        error={isSubmitted && !!errors.shipperContactDestination}
        color={`${isSubmitted ? "success" : "grey"}`}
        {...register("shipperContactDestination")}
        name="shipperContactDestination"
        label="Contact person at destination*"
        variant="filled"
      />
    </div>
  </>
);

ShipperDetails.propTypes = {
  isSubmitted: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
};

export default ShipperDetails;
