import { login as axiosLogin, logout as axiosLogout } from "../utils/axios";
import { observable, action, makeObservable } from "mobx";
import userDetails from "./user-details";
import auth from "../api/endpoints/auth";
import { axios } from "../utils/axios";

class Authentication {
  isAuthenticated = false;
  isAppLoaded = false;
  isLoading = false;
  error = null;
  // TODO update this logic when API will provide confirmation email
  emailConfirmationToken = null;

  isRegistration = false;
  isFirstLogin = false;

  async login(creds) {
    this.isLoading = true;
    this.error = null;
    userDetails.setIdentityError(null);
    try {
      const userData = await axiosLogin(creds);
      if (userData) {
        this.isAuthenticated = true;
      }
      if (this.isRegistration) {
        this.isFirstLogin = true;
      }
      this.isRegistration = false;
    } catch (error) {
      error.response?.data?.error_description === "The user credentials were incorrect." && this.setError("Wrong email or password");
      error.response?.data?.error_description === "access_denied_for_email_unconfirmed_user_exception_message" &&
        this.setError("Register unconfirmed, please check your email");
      console.error(error);
    }
    this.isLoading = false;
  }

  checkIfAuthenticated() {
    const accessToken = localStorage.getItem("access_token");
    const refreshToken = localStorage.getItem("refresh_token");
    if (accessToken && refreshToken) {
      this.setAuthenticated(true);
    }
  }

  async resetPassword(payload) {
    const path = auth.resetPasswordConfirmation.path;
    const method = auth.resetPasswordConfirmation.method;

    this.isLoading = true;
    this.error = null;
    try {
      return await axios[method](path, {
        password: payload.password,
        token: payload.token,
      });
    } catch (e) {
      console.error(e);
      this.setError(e.message);
      this.isLoading = false;
      return Promise.reject(e);
    }
  }

  async changeEmail(token) {
    const path = auth.changeEmail.path;
    const method = auth.changeEmail.method;
    const pathWithTokenID = path.replace(":emailConfirmationToken", token);
    this.isLoading = true;
    this.error = null;
    try {
      const userData = await axios[method](pathWithTokenID);
      if (userData.status) {
        this.emailConfirmationToken = null;
      } else {
        this.error = "Something went wrong! Please contact support or try again later.";
      }
    } catch (e) {
      console.error(e.message);
      this.error = "Something went wrong! Please contact support or try again later.";
    }
    this.isLoading = false;
  }

  async confirmEmail(token) {
    const path = auth.confirmEmail.path;
    const method = auth.confirmEmail.method;
    const pathWithTokenID = path.replace(":emailConfirmationToken", token);
    this.isLoading = true;
    this.error = null;
    try {
      const userData = await axios[method](pathWithTokenID);
      if (userData.status) {
        this.emailConfirmationToken = null;
      } else {
        this.error = "Something went wrong! Please contact support or try again later.";
      }
    } catch (e) {
      console.error(e.message);
      this.error = "Something went wrong! Please contact support or try again later.";
    }
    this.isLoading = false;
  }

  async contactUs(payload) {
    const path = auth.contactUs.path;
    const method = auth.contactUs.method;

    try {
      await axios[method](path, {
        ...payload,
      });
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  }

  setError(error) {
    this.error = error;
  }

  setAppLoadingStatus(isLoaded) {
    this.isAppLoaded = isLoaded;
  }

  // This function called when refresh token expired, from @utils/axios
  setAuthenticated(authenticated) {
    this.isAuthenticated = authenticated;
  }

  clearError() {
    this.error = null;
  }

  async resetPasswordWithEmail(email) {
    const path = auth.resetPassword.path;
    const method = auth.resetPassword.method;

    return await axios[method](path, {
      email,
      scope: "user",
    });
  }

  async registerUser(userPayload) {
    this.error = null;
    const path = auth.register.path;
    const method = auth.register.method;
    this.isLoading = true;
    try {
      await axios[method](path, userPayload);
      this.emailConfirmationToken = true;
      this.isRegistration = true;
      // await this.login({
      //   username: userPayload.email,
      //   password: userPayload.password,
      // });
    } catch (e) {
      if (e?.response?.data && e?.response?.data?.details) {
        const errorType = Object.keys(e.response.data.details);
        if (errorType && errorType.length) {
          this.error = errorType[0] === "email" ? "Email is already in use" : null;
        }
      } else {
        this.error = "Something went wrong! Please contact support or try again later.";
      }
    }
    this.isLoading = false;
  }

  async logout() {
    axiosLogout();
    userDetails.clearUserDetails();
    this.isAuthenticated = false;
  }

  setError(err) {
    this.error = err;
  }

  setLoading(loading) {
    this.isLoading = loading;
  }

  setFirstLogin(login) {
    this.isFirstLogin = login;
  };
}

const authentication = new Authentication();

makeObservable(authentication, {
  isAuthenticated: observable,
  isAppLoaded: observable,
  emailConfirmationToken: observable,
  error: observable,
  isLoading: observable,
  setAppLoadingStatus: action.bound,
  changeEmail: action.bound,
  setLoading: action.bound,
  resetPasswordWithEmail: action.bound,
  setError: action.bound,
  setFirstLogin: action.bound,
  resetPassword: action.bound,
  login: action.bound,
  logout: action.bound,
  confirmEmail: action.bound,
  checkIfAuthenticated: action.bound,
  setAuthenticated: action.bound,
  registerUser: action.bound,
  clearError: action.bound,
});

export default authentication;
