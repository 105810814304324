import { forwardRef } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { calendarInterpretation } from "../../helpers/contstants";
import styles from "./MessageList.module.scss";
import Message from "../Message/Message";

// eslint-disable-next-line react/display-name
const MessageList = forwardRef(({ messagesList, successMessageCreateAt, orderStatus }, ref) => {
  const successMessage = {
    createdAt: successMessageCreateAt,
    id: Math.random(),
    message: "Your order has been created successfully. Our staff will contact you through this form as soon as possible",
    admin: true,
  };
  const separationDates = [];

  const renderMessage = (message, index, className) => {
    const createdTime = moment(message.createdAt);
    const timeFromNow = createdTime.calendar(null, calendarInterpretation);
    if (!separationDates.includes(timeFromNow)) {
      separationDates.push(timeFromNow);
      return <Message message={message} key={message.id} order={index} timeFromNow={timeFromNow} createdTime={createdTime} className={className} />;
    } else return <Message message={message} key={message.id} order={index} createdTime={createdTime} />;
  };

  return (
    <ul className={`${styles.wrapper} ${orderStatus === "shipping_details_negotiation" && styles.buttonVisible }`} ref={ref}>
      {renderMessage(successMessage, -1, styles.success)}
      {messagesList?.map((message, index) => renderMessage(message, index))}
    </ul>
  );
});

MessageList.propTypes = {
  messagesList: PropTypes.array,
  successMessageCreateAt: PropTypes.number.isRequired,
  orderStatus: PropTypes.string.isRequired,
};

export default MessageList;
