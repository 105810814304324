import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import { useStores } from "../../../hooks/use-stores";
import Typography from "@mui/material/Typography";
import { NavLink } from "react-router-dom";
import { observer } from "mobx-react-lite";
import PropTypes from "prop-types";
import { useState } from "react";

import { ReactComponent as DashboardIcon } from "../../../assets/icons/dashboard.svg";
import { ReactComponent as LogoutIcon } from "../../../assets/icons/log-out.svg";
import { ReactComponent as SignInIcon } from "../../../assets/icons/sign-in.svg";
import { ReactComponent as SignUpIcon } from "../../../assets/icons/sign-up.svg";
import { ReactComponent as UserIcon } from "../../../assets/icons/user.svg";
import styles from "./AuthenticationMenu.module.scss";

const AuthenticationMenu = observer(({ closeMobileNavigation }) => {
  const {
    authentication: { isAuthenticated, logout },
    userDetails: { KYC },
  } = useStores();
  const [iconNode, setIconNode] = useState(null);

  const closePopover = () => {
    closeMobileNavigation();
    setIconNode(null);
  };

  const handleLogout = () => {
    closePopover();
    logout();
  };

  const leave = () => {
    setTimeout(() => setIconNode(null), 300);
  };

  return (
    <>
      <div className={styles.wrap}>
        <div className={styles.icon}>
          <UserIcon />
          <div className={styles.userMenuWrapper}>
            <div className={styles.userMenu}>
              <Typography className={styles.popover} onMouseLeave={leave}>
                {isAuthenticated ? (
                  <>
                    <NavLink className={styles.link} activeClassName={styles.selected} to="/dashboard" onClick={closePopover}>
                      <DashboardIcon className={styles.picture} />
                      <span className={styles.headline}>My dashboard</span>
                    </NavLink>
                    {KYC ? (
                      <NavLink className={`${styles.link} ${styles.scaled}`} activeClassName={styles.selected} to="/user-profile" onClick={closePopover}>
                        <ManageAccountsOutlinedIcon className={styles.picture} />
                        <span className={styles.headline}>My profile</span>
                      </NavLink>
                    ) : (
                      <NavLink className={`${styles.link} ${styles.scaled}`} activeClassName={styles.selected} to="/first-login" onClick={closePopover}>
                        <ManageAccountsOutlinedIcon className={styles.picture} />
                        <span className={styles.headline}>Update profile</span>
                      </NavLink>
                    )}
                    <button className={`${styles.link} ${styles.button}`} to="/register" onClick={handleLogout}>
                      <LogoutIcon className={styles.picture} />
                      <span className={styles.headline}>Log out</span>
                    </button>
                  </>
                ) : (
                  <>
                    <NavLink className={styles.link} activeClassName={styles.selected} to="/login" onClick={closePopover}>
                      <SignInIcon className={styles.picture} />
                      <span className={styles.headline}>Sign in</span>
                    </NavLink>
                    <NavLink className={styles.link} activeClassName={styles.selected} to="/register" onClick={closePopover}>
                      <SignUpIcon className={styles.picture} />
                      <span className={styles.headline}>Sign up</span>
                    </NavLink>
                  </>
                )}
              </Typography>
            </div>
          </div>
        </div>
      </div>
      {/* <Popover
        id={Boolean(iconNode) ? "mouse-over-popover" : undefined}
        onClose={closePopover}
        sx={{ marginTop: "5px" }}
        open={Boolean(iconNode)}
        anchorEl={iconNode}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}>
        <Typography className={styles.popover} onMouseLeave={leave}>
          {isAuthenticated ? (
            <>
              <NavLink className={styles.link} activeClassName={styles.selected} to="/dashboard" onClick={closePopover}>
                <DashboardIcon className={styles.picture} />
                <span className={styles.headline}>My dashboard</span>
              </NavLink>
              {KYC ? (
                <NavLink className={`${styles.link} ${styles.scaled}`} activeClassName={styles.selected} to="/user-profile" onClick={closePopover}>
                  <ManageAccountsOutlinedIcon className={styles.picture} />
                  <span className={styles.headline}>My profile</span>
                </NavLink>
              ) : (
                <NavLink className={`${styles.link} ${styles.scaled}`} activeClassName={styles.selected} to="/first-login" onClick={closePopover}>
                  <ManageAccountsOutlinedIcon className={styles.picture} />
                  <span className={styles.headline}>Update profile</span>
                </NavLink>
              )}
              <button className={`${styles.link} ${styles.button}`} to="/register" onClick={handleLogout}>
                <LogoutIcon className={styles.picture} />
                <span className={styles.headline}>Log out</span>
              </button>
            </>
          ) : (
            <>
              <NavLink className={styles.link} activeClassName={styles.selected} to="/login" onClick={closePopover}>
                <SignInIcon className={styles.picture} />
                <span className={styles.headline}>Sign in</span>
              </NavLink>
              <NavLink className={styles.link} activeClassName={styles.selected} to="/register" onClick={closePopover}>
                <SignUpIcon className={styles.picture} />
                <span className={styles.headline}>Sign up</span>
              </NavLink>
            </>
          )}
        </Typography>
      </Popover>*/}
    </>
  );
});

AuthenticationMenu.propTypes = {
  closeMobileNavigation: PropTypes.func,
};

export default AuthenticationMenu;
