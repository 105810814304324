import { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";

import CompanyDetailsForm from "../../components/CompanyDetailsForm/CompanyDetailsForm";
import SubscribePlan from "../../components/SubscribePlan/SubscribePlan";
import Payment from "../../components/Payment/Payment";
import { useStores } from "../../hooks/use-stores";
import Steps from "../../components/Steps/Steps";
import styles from "./FirstLogin.module.scss";
import { Modal } from "@mui/material";
import CancelMySubscription from "../UserProfile/CancelMySubscription/CancelMySubscription";
import ReactGA from "react-ga4";
/* eslint-disable */

const FirstLogin = () => {
  const [subscribeInfo, setSubscribeInfo] = useState();
  const {
    userDetails: { setCompanyDetails, KYC, updateCompanyDetails, KYCError, isPlanSelected },
    plan: { getPlanList, loading, planList, getClientSecret, error: planError },
  } = useStores();
  const [currentStep, setCurrentStep] = useState(1);
  const defaulCompanyDetails = useRef({});
  const history = useHistory();
  const [modalActive, setModalActive] = useState(false);

  useEffect(() => {
    if (KYC) {
      defaulCompanyDetails.current = {
        natureOfBusiness: KYC.natureOfBusiness,
        establishedDate: KYC.establishedDate,
        customerName: KYC.customerName,
        directorName: KYC.directorName,
        constitution: KYC.constitution,
        eori: KYC.eori,
        vat: KYC.vat,
        pan: KYC.pan,
      };
      setCurrentStep(2);
    }
  }, [KYC]);

  useEffect(() => isPlanSelected && history.push("/user-profile"), [isPlanSelected]);

  const companyDetailsFormHandler = async (data) => {
    if (KYC) {
      const response = await updateCompanyDetails(data);
      response && history.push("/user-profile");
    } else {
      const response = await setCompanyDetails(data);
      response && history.push("/user-profile");
    }
  };

  const subscribeHandler = async (subscribePlan) => {
    const response = await getClientSecret(subscribePlan.id);
    setSubscribeInfo(subscribePlan);
    response && setCurrentStep(2);
  };

  function paymentHandler() {
    setModalActive(true);
    ReactGA.event({
      category: "Popup start of work",
      action: "Popup start of work",
    });
  };

  function nextStep() {
    setCurrentStep(3);
    ReactGA.event({
      category: "Company data",
      action: "Company data",
    });
  };

  const toggleStep = (step) => setCurrentStep(step);

  useEffect(() => getPlanList(), []);

  const renderStepContent = (currentStep) => {
    switch (currentStep) {
      case 1: {
        return (
          <>
            <h2 className={styles.title}>Subscribe plan</h2>
            <SubscribePlan loading={loading} data={planList} subscribeHandler={subscribeHandler} type="subscribe" error={planError} />
          </>
        );
      }
      case 2: {
        return (
          <>
            <h2 className={styles.title}>Payment and details</h2>
            <Payment subscribeInfo={subscribeInfo} paymentHandler = {paymentHandler}/>
            <Modal className={styles.modal} open={modalActive} >
                <CancelMySubscription
                  head={<span>Thank you for subscribing, now we can get to work!</span>}
                  content={<span className={styles.modalInfo}>You can try for free for 7 days!</span>}
                  onCancel={nextStep}
                  onAccept={nextStep}
                  action={"Next"}
                />
            </Modal>
          </>
        );
      }
      case 3: {
        return (
          <>
            <h2 className={styles.title}>Company details</h2>
            <CompanyDetailsForm formHandler={companyDetailsFormHandler} type="create" defaultFormData={defaulCompanyDetails.current} error={KYCError} />
          </>
        );
      }
      default:
        return;
    }
  };

  return (
    <section className={styles.wrapper}>
      <Steps length={3} currentStep={currentStep} toggleStep={toggleStep} />
      {renderStepContent(currentStep)}
    </section>
  );
};

export default observer(FirstLogin);
