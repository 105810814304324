const REACT_APP_BASE_API = process.env.REACT_APP_BASE_API;
const REACT_APP_CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const REACT_APP_CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;

const configuration = {
  baseUrl: `${REACT_APP_BASE_API}/api/`,
  tokenUrl: `${REACT_APP_BASE_API}/api/token`,
  profileUrl: `${REACT_APP_BASE_API}/api/v1.0/admins/me`,
  params: {
    client_id: REACT_APP_CLIENT_ID,
    client_secret: REACT_APP_CLIENT_SECRET,
    scope: "user",
  },
};

export const storage = {
  userToken: "access_token",
  userRefreshToken: "refresh_token",
};

export default configuration;
