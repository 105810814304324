import PropTypes from "prop-types";
import { useState } from "react";

import ShippingInfo from "../ShippingInfo/ShippingInfo";
import ProductInfo from "../ProductInfo/ProductInfo";
import styles from "./OrderDetails.module.scss";
import Messenger from "../Messenger/Messenger";
import FilesList from "../FilesList/FilesList";

const OrderDetails = ({ order, orderCreateAt, onRedirect }) => {
  const isFilesAttached = order.productDetailsFile || order.productShipmentFile || order.messagesFiles?.length > 0;
  const [activeTab, setActiveTab] = useState("chat");
  const changeActiveTab = (tab) => () => setActiveTab(tab);

  const renderTabInfo = (activeTab) => {
    switch (activeTab) {
      case "chat": {
        return <Messenger onRedirect={onRedirect} orderID={order.id} successMessageCreateAt={orderCreateAt}/>;
      }
      case "product_info": {
        return <ProductInfo order={order} />;
      }
      case "shipping_info": {
        return <ShippingInfo order={order}/>;
      }
      case "files": {
        return <FilesList order={order}/>;
      }
      default:
        return;
    }
  };

  return (
    <article className={styles.wrapper}>
      <div className={styles.container}>
        <button className={`${styles.button} global-button ${activeTab === "chat" ? "" : "light-green"}`} onClick={changeActiveTab("chat")}>
          Chat
        </button>
        <button className={`${styles.button} global-button ${activeTab === "product_info" ? "" : "light-green"}`} onClick={changeActiveTab("product_info")}>
          Product info
        </button>
        {order.shipperContactDestination && <button className={`${styles.button} global-button ${activeTab === "shipping_info" ? "" : "light-green"}`} onClick={changeActiveTab("shipping_info")}>
          Shipping info
        </button>}
        {isFilesAttached && <button className={`${styles.button} global-button ${activeTab === "files" ? "" : "light-green"}`} onClick={changeActiveTab("files")}>
          Files
        </button>}
      </div>
      <div className={styles.receptacle}>{renderTabInfo(activeTab)}</div>
    </article>
  );
};

OrderDetails.propTypes = {
  order: PropTypes.object.isRequired,
  onRedirect: PropTypes.func.isRequired,
  orderCreateAt: PropTypes.object.string,
};

export default OrderDetails;
