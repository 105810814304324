import ProductShipment from "../../components/CreateOrder/ProductShipment";
import { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { Link, useHistory, useParams } from "react-router-dom";
import PropTypes from "prop-types";

import { ReactComponent as DashboardIcon } from "../../assets/icons/dashboard.svg";
import styles from "./ShippingDeatilsUpdate.module.scss";
import { useStores } from "../../hooks/use-stores";

const ShippingDeatilsUpdate = ({ location: { state } }) => {
  const [isLocationLoading, setLocationLoadingStatus] = useState(true);
  const {
    order: { error, isLoading, updateOrder, getCurrentOrder },
  } = useStores();
  const { id } = useParams();
  const currentOrder = useRef();
  const history = useHistory();

  const productShipmentFormHandler = async (formData, onSuccess, files) => {
    const data = { stage: "product_shipment", ...(files?.length && { shipmentDetailsFileId: files[0].id }), ...formData };
    const response = await updateOrder(currentOrder.current.id, data);
    if (response) {
      onSuccess();
      history.push({
        pathname: `/order/${currentOrder.current.id}`,
        state: { successMessage: "Your shipping deatils has been successfully updated." },
      });
    }
  };

  useEffect(() => {
    getCurrentOrder(id);
    if (state?.currentOrder) currentOrder.current = state.currentOrder;
    setLocationLoadingStatus(false);
    return () => history.replace();
  }, []);

  return (
    <section className={styles.wrapper}>
      {currentOrder.current ? (
        <>
          <p className={styles.text}>
            Order id: <strong>{currentOrder.current.id}</strong>
          </p>
          <ProductShipment
            defaultFormData={currentOrder.current}
            formDataHandler={productShipmentFormHandler}
            isLoading={isLoading}
            error={error}
            update
          />
        </>
      ) : isLocationLoading ? (
        <p>Loading...</p>
      ) : (
        <div className={styles.container}>
          <h3 className={styles.notification}>Access denied</h3>
          <Link className={`${styles.link} global-button white`} to="/dashboard">
            <DashboardIcon className={styles.icon} />
            Back to Dashboard
          </Link>
        </div>
      )}
    </section>
  );
};

ShippingDeatilsUpdate.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      currentOrder: PropTypes.object,
    }),
  }).isRequired,
};

export default observer(ShippingDeatilsUpdate);
