export default {
  create: {
    path: "/order",
    method: "post",
  },
  list: {
    path: "/order",
    method: "get",
  },
  get: {
    path: "/order/:id",
    method: "get",
  },
  cancel: {
    path: "/order/:id/switch-status",
    method: "patch",
  },
  update: {
    path: "/order/:id",
    method: "patch",
  },
};
