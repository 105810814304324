import { ReactComponent as Illustration } from "../../assets/images/illustration.svg";
import FileUploader from "../../components/FileUploader/FileUploader";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "@mui/material";
import * as Yup from "yup";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { emailRegExp } from "../../helpers/contstants";
import { useStores } from "../../hooks/use-stores";

const validationSchema = Yup.object().shape({
  companyName: Yup.string()
    .required("This field is required")
    .min(3, "Company name must be at least 3 characters")
    .max(150, "Company name must not exceed 150 characters"),
  message: Yup.string()
    .required("This field is required")
    .min(2, "Message must be at least 2 characters")
    .max(500, "Message must not exceed 500 characters"),
  productTypes: Yup.string()
    .required("This field is required")
    .min(3, "Product type must be at least 3 characters")
    .max(150, "Product type must not exceed 150 characters"),
  companyEmail: Yup.string()
    .required("This field is required")
    .matches(emailRegExp, { message: "enter a valid email before and after '@'", excludeEmptyString: true }),
});

const Contact = () => {
  const [suc, setSuc] = useState(false)
  const [loading, setLoading] = useState(false)

  const {
    authentication: { contactUs }
  } = useStores();
  const uploaderNode = useRef();

  const {
    formState: { errors, isSubmitted },
    handleSubmit,
    reset,
    register,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const dataHandler = async (data) => {
    setSuc(false)
    setLoading(true)
    const filesInfo = uploaderNode.current.getFilesInfo();
    if (filesInfo[0]) {
      data.fileId = filesInfo[0]?.id
    }

    try {
      await contactUs(data)
      setSuc(true)
      reset({});
      setLoading(false)
    } catch (e) {
      console.error(e)
      setLoading(false)
    }
    uploaderNode.current.clearFilesInfo();
  }

  return (
    <div className="container">
      <section className="section-contact-us">
        <div className="contact-us__row">
          <div className="contact-us__column">
            <h1 className="h1">Contact us</h1>
            <h3 className="h3">
              We are open to a new partnership! Don’t hesitate to contact us!
            </h3>
            <h3 className="h3">
              Contact form for Manufacturers:
            </h3>
            <div className="form-wrapper">
              <form className="form form--contact" id="form" onSubmit={handleSubmit(dataHandler)}>
                <TextField
                  helperText={isSubmitted && errors.companyName ? errors.companyName.message : " "}
                  color={`${isSubmitted ? "success" : "grey"}`}
                  error={isSubmitted && !!errors.companyName}
                  {...register("companyName")}
                  label="Company name*"
                  name="companyName"
                  variant="filled"
                />
                <div>
                  <TextField
                    helperText={isSubmitted && errors.companyEmail ? errors.companyEmail.message : " "}
                    color={`${isSubmitted ? "success" : "grey"}`}
                    error={isSubmitted && !!errors.companyEmail}
                    {...register("companyEmail")}
                    label="Company email*"
                    name="companyEmail"
                    variant="filled"
                  />
                </div>
                <div>
                  <TextField
                    helperText={isSubmitted && errors.productTypes ? errors.productTypes.message : " "}
                    color={`${isSubmitted ? "success" : "grey"}`}
                    error={isSubmitted && !!errors.productTypes}
                    {...register("productTypes")}
                    label="What products are you producing?*"
                    name="productTypes"
                    variant="filled"
                  />
                </div>
                <div>
                  <TextareaAutosize
                    color={`${isSubmitted ? "success" : "grey"}`}
                    error={isSubmitted && !!errors.message}
                    placeholder="Your message"
                    className="global-textarea"
                    aria-label="maximum height"
                    {...register("message")}
                    maxLength={500}
                    minRows={4}
                    maxRows={4}
                  />
                </div>
                <div className="form-file">
                  <FileUploader inputID="find-product-file-uploader" ref={uploaderNode} />
                </div>
                <button className="button" id="submit" type="submit" disabled={loading}>
                  Submit
                </button>
              </form>
              {suc && <h4 className="success-message" id="success-message">Data was successfully sent</h4>}
            </div>
            <h4 className="success-message hidden" id="success-message">Data was successfully sent</h4>
            <h3 className="h3">
              For all other questions please contact:
            </h3>
            <div className="contact-us__email">
              <svg width="24" height="25" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 25">
                <path d="M5.672 6.266h11.974l-2.999-2.943L12 .5 5.672 6.266Z" fill="#49B449"/>
                <path
                  d="M19.66 13.098V5.432c0-.647-.524-1.172-1.171-1.172H4.36c-.647 0-1.172.525-1.172 1.172v7.666l6.678 4.745 1.235-.679c.347-.19.767-.19 1.114 0l1.236.68 6.208-4.746Z"
                  fill="#fff"/>
                <path
                  d="M1.352 22.991c0 .604.463 1.1 1.053 1.151l7.462-5.83-8.164-8.15c-.225.22-.351.522-.351.836v11.993Z"
                  fill="#fff"/>
                <path
                  d="M2.508 24.147H20.81c.56-.541.56-.541 1.156-1.156l-9.75-5.359a1.156 1.156 0 0 0-1.114 0l-9.751 5.359c0 .638.517 1.156 1.156 1.156Z"
                  fill="#49B449"/>
                <path
                  d="M20.56 22.218v.773c0 .638-.517 1.156-1.155 1.156h1.406c.639 0 1.156-.518 1.156-1.156l-1.406-.773Z"
                  fill="#49B449"/>
                <path
                  d="M13.125 8.34a.351.351 0 0 0-.35-.354h-.002a.352.352 0 0 0-.351.35l-.001.177a1.437 1.437 0 0 0-.889-.3c-.83 0-1.687.717-1.687 1.918 0 1.094.742 1.985 1.653 1.985.396 0 .755-.167 1.034-.448.055.112.127.215.216.304.196.197.448.305.71.305.76 0 1.242-.529 1.44-1.024.305-.769.36-1.417.177-2.1 0-.004-.002-.008-.003-.011a3.517 3.517 0 0 0-1.74-2.111 3.517 3.517 0 0 0-2.726-.241A3.525 3.525 0 0 0 8.58 8.46a3.52 3.52 0 0 0-.326 2.603c.2.76.63 1.415 1.247 1.895a3.57 3.57 0 0 0 2.08.749h.01a.352.352 0 0 0 .01-.703 2.857 2.857 0 0 1-2.668-2.12A2.82 2.82 0 0 1 9.197 8.8 2.825 2.825 0 0 1 10.82 7.46a2.82 2.82 0 0 1 2.185.193c.676.356 1.17.954 1.393 1.685.15.563.06 1.12-.153 1.655-.07.175-.285.581-.785.581a.312.312 0 0 1-.213-.098.484.484 0 0 1-.135-.338l.014-2.799Zm-1.627 3.073c-.457 0-.95-.49-.95-1.283 0-.76.5-1.214.984-1.214.505 0 .863.47.881 1.149v.128c-.027.68-.427 1.22-.915 1.22Z"
                  fill="#263238"/>
                <path
                  d="M22.319 22.993V10.999c0-.404-.165-.799-.453-1.083l-.001-.001-.003-.003-1.381-1.356v-.793a.352.352 0 1 0-.703 0v3.739l-6.388 6.376-1.005-.552a1.5 1.5 0 0 0-1.452 0l-1.004.552L3.54 11.5V5.434c0-.452.368-.82.82-.82h14.596c.453 0 .82.368.82.82v.834a.352.352 0 1 0 .704 0v-.834c0-.84-.684-1.523-1.524-1.523h-3.21L12.682.902a1.502 1.502 0 0 0-2.035-.01l-.01.01L7.572 3.91h-3.21c-.84 0-1.524.683-1.524 1.523v3.123L1.456 9.912l-.001.002h-.001l-.002.002A1.533 1.533 0 0 0 1 11v4.012a.352.352 0 0 0 .703 0v-4.012c0-.098.019-.195.053-.286l1.185 1.183 6.346 6.334-7.584 4.168v-5.855a.351.351 0 1 0-.703 0v6.455A1.51 1.51 0 0 0 2.508 24.5h12.978a.351.351 0 1 0 0-.703H2.508a.805.805 0 0 1-.78-.61l8.308-4.566 1.236-.68a.8.8 0 0 1 .775 0l1.235.68 8.309 4.566a.805.805 0 0 1-.78.61h-3.793a.351.351 0 1 0 0 .703h3.793a1.51 1.51 0 0 0 1.508-1.502v-.005ZM11.124 1.409a.802.802 0 0 1 1.07 0l2.549 2.5H8.576l2.548-2.5Zm-8.286 9.39-.636-.634.636-.624V10.8Zm17.54 1.097 1.185-1.183a.819.819 0 0 1 .053.286v11.399l-7.584-4.168 6.346-6.334Zm.738-1.731-.635.635V9.54l.635.624Z"
                  fill="#263238"/>
              </svg>
              <a href="mailto:info@smart-drop.co.uk">info@smart-drop.co.uk</a>
            </div>
          </div>
          <div className="contact-us__column">
            <div className="hero-one">
              <div className="hero-one__inner">
                <Illustration />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
