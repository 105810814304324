import { observable, action, makeObservable } from "mobx";
import messages from "../api/endpoints/messsages";
import { axios } from "../utils/axios";
import orderStore from "./order";

class Messages {
  unreadList = [];
  messagesList = [];

  async getMessages(orderId) {
    const path = messages.messagesList.path;
    const method = messages.messagesList.method;

    try {
      const { results } = await axios[method](path, {
        params: {
          "filter[order]": orderId,
          limit: 10000,
          sortField: "createdAt",
          sortDirection: "ASC",
        },
      });
      results.length !== this.messagesList.length && this.setMessages(results);
      const unreadedMessagesIds = this.getUnreadedIds(results);
      if (results.length) {
        const { order } = results[0];
        orderStore.updateOrderStatus(order.status);
      }
      if (unreadedMessagesIds.length) {
        await this.bulkRead(+orderId, unreadedMessagesIds);
        await this.getNotifications();
      }
    } catch (e) {
      console.error(e);
    }
  }

  async bulkRead(orderId, msgs) {
    const path = messages.bulkRead.path;
    const method = messages.bulkRead.method;

    try {
      await axios[method](path, {
        orderId,
        messages: msgs,
      });
    } catch (e) {
      console.error(e);
    }
  }

  async sendMessage(payload) {
    const path = messages.creatMessage.path;
    const method = messages.creatMessage.method;

    try {
      await axios[method](path, {
        ...payload,
      });
      return Promise.resolve();
    } catch (e) {
      console.error(e);
    }
  }

  async getNotifications() {
    const path = messages.notifications.path;
    const method = messages.notifications.method;

    try {
      const response = await axios[method](path);
      this.setUnreadList(response);
    } catch (e) {
      console.error(e);
    }
  }

  setMessages(messages) {
    this.messagesList = messages;
  }

  setUnreadList(notifications) {
    this.unreadList = notifications;
  }

  getUnreadedIds(msgs) {
    const ids = [];
    for (const msg of msgs) {
      if (!msg.isRead && msg.admin) {
        ids.push(msg.id);
      }
    }
    return ids;
  }
}

const messagesStore = new Messages();

makeObservable(messagesStore, {
  getNotifications: action.bound,
  setUnreadList: action.bound,
  getMessages: action.bound,
  getUnreadedIds: action.bound,
  bulkRead: action.bound,
  setMessages: action.bound,
  messagesList: observable,
  unreadList: observable,
});

export default messagesStore;
