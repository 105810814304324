import PropTypes from "prop-types";
import { Fragment } from "react";

import styles from "./Steps.module.scss";

const Steps = ({ length, currentStep, toggleStep }) => {
  const changeStep = (step) => () => toggleStep(step);
  return (
    <ul className={styles.wrapper}>
      {[...Array(length).keys()].map((_, index) => (
        <Fragment key={index}>
          <li className={`${styles.item} ${currentStep >= index + 1 ? styles.active : ""}`} onClick={changeStep(index + 1)}>
            {index < 10 ? `0${index + 1}` : index + 1}
          </li>
          {index !== length - 1 && <hr className={`${styles.line} ${currentStep >= index + 1 ? styles.active : ""}`} />}
        </Fragment>
      ))}
    </ul>
  );
};

Steps.propTypes = {
  currentStep: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
  toggleStep: PropTypes.func.isRequired,
};

export default Steps;
