import { useForm, Controller } from "react-hook-form";
import PropTypes from "prop-types";

import { emailRegExp } from "../../helpers/contstants";
import styles from "./Form.module.scss";

import PhoneInput, { isPossiblePhoneNumber, isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import ReCAPTCHA from "react-google-recaptcha";

/* eslint-disable */

const Form = ({ type, className, dataHandler = () => {}, apiErrorMessage, loading, link }) => {
  const {
    formState: { errors, isSubmitted, dirtyFields, isValid },
    handleSubmit,
    register,
    control,
  } = useForm({ mode: "onChange" });

  return (
    <form className={`${styles.wrapper} ${className}`} onSubmit={handleSubmit(dataHandler)}>
      {type === "register" && (
        <>
          <div className={styles.field}>
            <input
              className={`${styles.input} ${!errors.name && dirtyFields.name ? styles.correct : ""} ${
                errors.name && isSubmitted ? styles.error : ""
              }`}
              {...register("name", { required: true, minLength: 2 })}
              placeholder="Full name"
            />
            <p className={styles.mistake}>
              {errors.name &&
                isSubmitted &&
                (errors.name.type === "required" ? "This field is required" : "Length should be 2 symbols at least")}
            </p>
          </div>
          <div className={styles.field}>
               <Controller
                  name="phone"
                  control={control}
                  rules={{required: true }}
                  render={({ field: { onChange, value } }) => (
                    <PhoneInput
                      international
                      name="phone"
                      className={`${styles.input} ${!errors.phone && dirtyFields.phone ? styles.correct : ""} ${errors.phone && isSubmitted ? styles.error : ""}`}
                      value={value}
                      onChange={onChange}
                      defaultCountry="GB"
                      id={styles.phoneInput}
                    />
                  )}
                />
              <p className={styles.mistake}>
                {errors.phone &&
                  isSubmitted &&
                  (errors.phone.type === "required" ? "This field is required" : "Please, enter valid phone number")}
              </p>
          </div>
        </>
      )}

      {type !== "set password" && (
        <div className={styles.field}>
          <input
            className={`${styles.input} ${!errors.email && dirtyFields.email ? styles.correct : ""} ${
              errors.email && isSubmitted ? styles.error : ""
            }`}
            {...register("email", { required: true, pattern: emailRegExp })}
            placeholder={type === "register" || type === "recover" ? "Email address" : "Login"}
          />
          <p className={styles.mistake}>
            {errors.email &&
              isSubmitted &&
              (errors.email.type === "required" ? "This field is required" : "enter a valid email before and after '@'")}
          </p>
        </div>
      )}

      {(type === "login" || type === "register" || type === "set password") && (
        <div className={styles.field}>
          <input
            className={`${styles.input} ${!errors.password && dirtyFields.password ? styles.correct : ""} ${
              errors.password && isSubmitted ? styles.error : ""
            }`}
            {...register("password", { required: true, ...(type !== "login" && { minLength: 8 }) })}
            placeholder={type === "set password" ? "New password" : "Password"}
            type="password"
          />
          <p className={styles.mistake}>
            {errors.password &&
              isSubmitted &&
              (errors.password.type === "required" ? "This field is required" : "Length should be 8 symbols at least")}
          </p>
        </div>
      )}

      {(type === "register" || type === "set password") && (
        <div className={styles.field}>
          <input
            className={`${styles.input} ${!errors.confirm && dirtyFields.confirm ? styles.correct : ""} ${
              errors.confirm && isSubmitted ? styles.error : ""
            }`}
            {...register("confirm", { required: true, minLength: 8 })}
            placeholder={type === "set password" ? "Confirm new password" : "Confirm Password"}
            type="password"
          />
          <p className={styles.mistake}>
            {errors.confirm &&
              isSubmitted &&
              (errors.confirm.type === "required" ? "This field is required" : "Length should be 8 symbols at least")}
          </p>
        </div>
      )}

      {(type === "register") && (
        <div className={styles.recaptchaField}>
          <Controller
            name="captchaCode"
            control={control}
            rules={{ required: true}}
            render={({ field: { onChange, value } }) => (
                <ReCAPTCHA
                  className={`${styles.input} ${!errors.captchaCode && dirtyFields.captchaCode ? styles.correct : ""} ${errors.captchaCode && isSubmitted ? styles.error : ""}`}
                  sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
                  onChange={onChange}
                  value={value}
                />
            )}
            />
            <p className={styles.mistake}>
              {errors.captchaCode &&
                isSubmitted &&
                (errors.captchaCode.type === "required" ? "This field is required" : "This field is required")}
            </p>
          </div>
      )}

      <p className={styles.notification}>{apiErrorMessage}</p>
      <button className="global-button" disabled={(isSubmitted && !isValid) || loading}>
        {type}
      </button>
      {link}
    </form>
  );
};

Form.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string.isRequired,
  dataHandler: PropTypes.func,
  apiErrorMessage: PropTypes.any,
  loading: PropTypes.bool,
  link: PropTypes.element,
};

export default Form;
