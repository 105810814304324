import { Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import PropTypes from "prop-types";

import styles from "./Info.module.scss";

const Info = ({ info, className }) => {
  const isInfoEmpty = !Object.keys(info).length;
  return (
    <TableContainer className={`${styles.wrapper} ${className}`}>
      {isInfoEmpty ? (
        <p className={styles.notification}>There are no any information yet</p>
      ) : (
        <Table sx={{ border: "none" }} aria-label="simple table">
          <TableBody>
            {Object.entries(info).map((item, index) =>
              item[0] === "File" ? (
                <TableRow key={index}>
                  <TableCell sx={{ border: "none", fontWeight: 600, fontSize: "16px" }} component="th" scope="row">
                    {item[0]}:
                  </TableCell>
                  <TableCell component="th" sx={{ border: "none", fontSize: "16px" }} scope="row">
                    <a className={styles.link} href={item[1].url} target="_blank" rel="noreferrer">
                      {item[1].file.originalName}
                    </a>
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow key={index}>
                  <TableCell sx={{ border: "none", fontWeight: 600, fontSize: "16px" }} component="th" scope="row">
                    {item[0]}:
                  </TableCell>
                  <TableCell sx={{ border: "none", fontSize: "16px" }} component="th" scope="row">
                    {item[1]}
                  </TableCell>
                </TableRow>
              ),
            )}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};

Info.propTypes = {
  className: PropTypes.string,
  info: PropTypes.shape({
    cargo: PropTypes.string,
    kindOfGoods: PropTypes.string,
    dimensions: PropTypes.string,
    category: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string,
    item: PropTypes.string,
  }),
};

export default Info;
