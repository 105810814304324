import PropTypes from "prop-types";
import { useState } from "react";

import styles from "./ShippingInfo.module.scss";
import Info from "../Info/Info";

const ShippingInfo = ({ order }) => {
  const [activeTab, setActiveTab] = useState("Shipper details");
  const changeActiveTab = (tab) => () => setActiveTab(tab);

  const renderTabInfo = (activeTab) => {
    switch (activeTab) {
      case "Shipper details": {
        return (
          <Info
            className={styles.box}
            info={{
              ...(order.shipperContactDestination && { "Contact person at destination": order.shipperContactDestination }),
              ...(order.shipperContactPerson && { "Contact person": order.shipperContactPerson }),
              ...(order.shipperPostcode && { Postcode: order.shipperPostcode }),
              ...(order.shipperCountry && { Country: order.shipperCountry }),
              ...(order.shipperName && { "Shipper name": order.shipperName }),
              ...(order.shipperEmail && { Email: order.shipperEmail }),
              ...(order.shipperState && { State: order.shipperState }),
              ...(order.shipperPhone && { Phone: order.shipperPhone }),
              ...(order.shipperCity && { City: order.shipperCity }),
            }}
          />
        );
      }
      case "Consignee details": {
        return (
          <Info
            className={styles.box}
            info={{
              ...(order.consigneeContactDestination && { "Contact person at destination": order.consigneeContactDestination }),
              ...(order.consigneeContactPerson && { "Contact person": order.consigneeContactPerson }),
              ...(order.consigneePostcode && { Postcode: order.consigneePostcode }),
              ...(order.consigneeCountry && { Country: order.consigneeCountry }),
              ...(order.consigneeName && { "Shipper name": order.consigneeName }),
              ...(order.consigneePhone && { Phone: order.consigneePhone }),
              ...(order.consigneeEmail && { Email: order.consigneeEmail }),
              ...(order.consigneeState && { State: order.consigneeState }),
              ...(order.consigneeCity && { City: order.consigneeCity }),
            }}
          />
        );
      }
      case "Cargo details": {
        return (
          <Info
            className={styles.box}
            info={{
              ...(order.placeOfDischarge && { "Place of discarge": order.placeOfDischarge }),
              ...(order.placeOfLoading && { "Place of loading": order.placeOfLoading }),
              ...(order.shippingMethod && { "Shipping method": order.shippingMethod }),
              ...(order.incoterms && { Incoterms: order.incoterms }),
              ...(order.commodity && { Commodity: order.commodity }),
              ...(order.remarks && { Remarks: order.remarks }),
            }}
          />
        );
      }
      default:
        return;
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <button className={`${styles.button} ${activeTab === "Shipper details" ? styles.active : ""}`} onClick={changeActiveTab("Shipper details")}>
          Shipper details
        </button>
        <button className={`${styles.button} ${activeTab === "Consignee details" ? styles.active : ""}`} onClick={changeActiveTab("Consignee details")}>
          Consignee details
        </button>
        <button className={`${styles.button} ${activeTab === "Cargo details" ? styles.active : ""}`} onClick={changeActiveTab("Cargo details")}>
          Cargo details
        </button>
      </div>
      <div className={styles.receptacle}>{renderTabInfo(activeTab)}</div>
    </div>
  );
};

ShippingInfo.propTypes = {
  order: PropTypes.object.isRequired,
};

export default ShippingInfo;
